export function DarkerColorConverter(hexColor: string, factor: number): string {
    // Ensure the factor is within the range [0, 1]
    factor = Math.max(0, Math.min(1, factor));

    // Convert hex to RGB
    let r: number = parseInt(hexColor.substring(1, 3), 16);
    let g: number = parseInt(hexColor.substring(3, 5), 16);
    let b: number = parseInt(hexColor.substring(5, 7), 16);

    // Darken the color
    r = Math.round(r * (1 - factor));
    g = Math.round(g * (1 - factor));
    b = Math.round(b * (1 - factor));

    // Ensure the values are within the valid range [0, 255]
    r = Math.min(255, Math.max(0, r));
    g = Math.min(255, Math.max(0, g));
    b = Math.min(255, Math.max(0, b));

    // Convert RGB back to hex
    const darkenedColor = `#${(r).toString(16).padStart(2, '0')}${(g).toString(16).padStart(2, '0')}${(b).toString(16).padStart(2, '0')}`;

    return darkenedColor;
}