export const ParseScript = {
    Parse(text: string, granStart: string, granFinish: string): string {
        text = text.substring(text.indexOf(granStart) + granStart.length);
        text = text.split(granFinish)[0];
        return text
    },
    ParseImg(text: string, granStart: string, granFinish: string): string[] {
        text = text.substring(text.indexOf(granStart) + granStart.length);
        text = text.split(granFinish)[0];
        let srcs: string[] = []
        text = text.replace(' ', '')

        let ok = true

        while (ok) {
            let r = text.indexOf('src="')
            console.log(r)
            if (r != -1) {
                text = text.substring(r + 'src="'.length);
                srcs.push(text.split('"')[0])
            } else ok = false

        }

        return srcs
    }
}