import React, {memo, useEffect, useState} from 'react';
import Select from "react-select";
import {Discount} from "../../../entities/DataTransferObjects/Discount";
import {DiscountAPI} from "../../../entities/api/DiscountAPI";
import {LocalStorage} from "../../../entities";
import {CalculateDiscountRequest} from "../../../entities/models/CalculateDiscountRequest";

interface p {
    selectedDiscountId: number,
    setSelectedDiscountId: (id: number) => void,
    amount: number
    setDiscountAmount: (discount: number) => void,
    target: string
}

const AsyncSelectDiscount = memo((p: p) => {

    const [discounts, setDiscounts] = useState<Discount[]>([])
    const [selected, setSelected] = useState<Discount | null>(null)

    useEffect(() => {
        DiscountAPI.getDiscountsByTarget(p.target, LocalStorage.userId()!).then((r) => {
            setDiscounts(r.data)
            if (p.selectedDiscountId != 0) {
                let d = r.data.find(n => n.id === p.selectedDiscountId)
                setSelected(d ? d : null)
            }

        })
    }, [])

    useEffect(() => {
        if (selected === null) {
            p.setDiscountAmount(0)
            p.setSelectedDiscountId(0)
            return
        }
        let data: CalculateDiscountRequest = {
            discountId: selected.id,
            checkTarget: p.target,
            price: p.amount
        }
        DiscountAPI.calculateDiscount([data]).then((r) => {
            p.setDiscountAmount(r.data[0])
            p.setSelectedDiscountId(selected.id)
        })
    }, [selected, p.amount])

    return (
        <div style={{color: 'black'}}>
            <Select
                isClearable
                isSearchable
                options={discounts}
                value={selected}
                onChange={(r) => {
                    setSelected(r)
                }}
                getOptionLabel={label => label!.name}
                getOptionValue={value => value!.name}
                placeholder={'Без знижки'}
                noOptionsMessage={() => 'Немає опцій'}
            />
        </div>
    );
});

export default AsyncSelectDiscount;