import React, {useEffect} from 'react';
import s from './LostCalls.module.scss'
import LostCallsRow from "./LostCallsRow";
import {useClientManager} from "../../ClientManagerStore";

const LostCallsTable = () => {
    const l = useClientManager(n => n.loadLostClientsData)
    const data = useClientManager(n => n.lostClientsData)
    useEffect(() => {
        l()
    }, [])
    return (
        <div className={s.table}>
            {data ? data.calls.map(call => <LostCallsRow call={call}
                                                         interactions={data.interactions[call.secondId]}/>) : null}
        </div>
    );
};

export default LostCallsTable;