import React, {useEffect, useState} from 'react';
import s from './DrivePage.module.scss'
import {UniTreeView} from "../../../shared/ui";
import {useSnackbar} from "notistack";
import {useDrivePage} from "./DrivePageStore";
import {Button, TextField} from "@mui/material";
import {FileUploader} from "react-drag-drop-files";
import {useAuth} from "../../../entities";
import UrlPreviewModal from "../../../widgets/workspace/UrlPreviewModal/UrlPreviewModal";

const DrivePage = () => {

    const {enqueueSnackbar} = useSnackbar()

    const loadFolders = useDrivePage(s => s.loadFolders)
    const data = useDrivePage(s => s.foldersTree)
    const getFilesTable = useDrivePage(s => s.getFilesTable)
    const filesTable = useDrivePage(s => s.filesTable)
    const uploadFile = useDrivePage(s => s.uploadFile)
    const grantAccess = useDrivePage(s => s.grantAccess)

    const fileName = useDrivePage(s => s.fileName)
    const setFileName = useDrivePage(s => s.setFileName)

    const createDoc = useDrivePage(s => s.createDoc)
    const createSheet = useDrivePage(s => s.createSheet)
    const createFolder = useDrivePage(s => s.createFolder)
    const deleteFile = useDrivePage(s => s.deleteFile)

    const setSelectedFolder = useDrivePage(s => s.setSelectedFolder)
    const setSelectedFile = useDrivePage(s => s.setSelectedFile)

    const selectedFolder = useDrivePage(s => s.selectedFolder)

    const user = useAuth(s => s.user)

    const [data2, setData2] = useState<any[]>([])

    const [view, setView] = useState(false)
    const [url, setUrl] = useState('')

    useEffect(() => {
        setData2(data.map(n => {
            return ({
                ...n,
                id: n.documentId,
                parentId: n.parents.length > 0 ? n.parents[0] === '0AF0yv_JOrwprUk9PVA' ? 0 : n.parents[0] : 0,
                name: n.fileName,
                isCollapsed: false
            })
        }))
    }, [data])

    useEffect(() => {
        loadFolders()
    }, [])

    const fileTypes = ["JPG", "PNG", "DOC", "DOCX", "XLS", "XLSX", "EXCEL", "PDF", "DOCX"];


    const [file, setFile] = useState(null);
    const handleChange = (file: any) => {
        console.log(file)
        uploadFile(file);

    };

    return (
        <div className={s.wrapper}>
            <UrlPreviewModal open={view} setOpen={setView} url={url}/>
            <div className={s.tree_container}>
                <UniTreeView data={data2} selected={selectedFolder ? {
                    ...selectedFolder,
                    id: selectedFolder.documentId,
                    parentId: selectedFolder.parents.length > 0 ? selectedFolder.parents[0] === '0AF0yv_JOrwprUk9PVA' ? 0 : selectedFolder.parents[0] : 0,
                    name: selectedFolder.fileName,
                    isCollapsed: false
                } : null} setSelected={setSelectedFolder}
                             onNodeClick={(d) => {
                                 getFilesTable(d.documentId)
                             }}/>
            </div>
            <div className={s.data_container}>
                <div className={s.head}>
                    <FileUploader handleChange={handleChange} name="file" types={fileTypes} classes={s.drop_zone}/>
                    <TextField placeholder={'Назва файлу'} value={fileName}
                               onChange={e => setFileName(e.target.value)}/>

                    <Button disabled={fileName.length < 3} variant={'contained'} onClick={() => createFolder(() => {
                        enqueueSnackbar('Папку створено', {
                            variant: 'success',
                            autoHideDuration: 3000
                        })
                    })}>+ папка</Button>

                    <Button disabled={fileName.length < 3} variant={'contained'} onClick={() => createDoc(() => {
                        enqueueSnackbar('Документ створено', {
                            variant: 'success',
                            autoHideDuration: 3000
                        })
                    })}>+ документ</Button>
                    <Button disabled={fileName.length < 3} variant={'contained'} onClick={() => createSheet(() => {
                        enqueueSnackbar('Таблицю створено', {
                            variant: 'success',
                            autoHideDuration: 3000
                        })
                    })}>+ таблиця</Button>
                </div>
                <div className={s.data} onDragCapture={() => {
                    console.log('onDragCapture')
                }}>
                    {
                        filesTable.sort((a, b) => {
                            if (a.mimeType.includes('folder') && !b.mimeType.includes('folder')) return -1
                            else return 1
                        }).map(r => {
                            return (<div className={s.row} onClick={() => {
                                setSelectedFile(r)
                            }}>
                                <img className={s.icon} src={r.iconUrl ? r.iconUrl : undefined}/>
                                <div className={s.name} onClick={() => {
                                    if (r.viewUrl != null) {
                                        if (!(!r.mimeType.includes('document') && !r.mimeType.includes('spreadsheet'))) {
                                            setUrl(r.viewUrl)
                                            setView(true)
                                        }

                                    }
                                    if (r.mimeType.includes('folder')) {
                                        getFilesTable(r.documentId)
                                        setSelectedFolder(r)
                                    }
                                }}>{r.fileName}</div>
                                <div className={s.buttons}>
                                    <Button variant={'outlined'} onClick={() => {
                                        if (user === undefined || user.email.length < 5) {
                                            enqueueSnackbar('У користувача некоректна пошта', {
                                                variant: 'warning',
                                                autoHideDuration: 3000
                                            })
                                            return
                                        }
                                        grantAccess(user.email, r.documentId, () => {
                                            enqueueSnackbar('Доступ надано', {
                                                variant: 'success',
                                                autoHideDuration: 3000
                                            })
                                        })
                                    }}>Доступ</Button>
                                    <Button variant={'outlined'} color={'warning'}
                                            onClick={() => {
                                                deleteFile(r.documentId, () => {
                                                    enqueueSnackbar('Файл видалено', {
                                                        variant: 'success',
                                                        autoHideDuration: 3000
                                                    })
                                                })
                                            }}>Видалити</Button>
                                    <Button variant={'outlined'} disabled={r.dowloadUrl === null} onClick={() => {
                                        if (r.dowloadUrl) window.open(r.dowloadUrl)
                                    }}>Завантажити</Button>
                                    <Button variant={'outlined'} disabled={r.viewUrl === null} onClick={() => {
                                        if (r.viewUrl) window.open(r.viewUrl)
                                    }}>+вкладка</Button>
                                </div>

                            </div>)
                        })
                    }
                </div>

            </div>
        </div>
    );
};

export default DrivePage;