import React from 'react';
import {Button, CustomModal} from "../../../../shared/ui";
import s from './ConfirmOrderIssueModal.module.scss'
import useOrderManager from "../../../../features/OrderManager/OrderManagerStore";

const ConfirmOrderIssueModal = (p: { open: boolean, setOpen: (v: boolean) => void }) => {
    const issue = useOrderManager(s => s.issue)
    const currentOrder = useOrderManager(s => s.currentOrder)

    return (
        <CustomModal
            open={p.open}
            onClose={() => {
                p.setOpen(false)
            }}
        >
            <div className={s.wrapper}>
                <div className={s.info}>{"Замовлення №" + currentOrder?.order.id}</div>

                <Button onClick={() => {
                    issue(currentOrder ? currentOrder.order.id : 0)
                    p.setOpen(false)
                }}>Підтвердити видачу замовлення</Button>
            </div>
        </CustomModal>
    );
};

export default ConfirmOrderIssueModal;