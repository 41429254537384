import React, {useEffect, useState} from 'react';
import s from './NomenclatureManagerPage.module.scss'
import {useNomenclatureManager} from "./NomenclatureManagerPageStore";
import Pagination from "../../../shared/ui/UserInterfaceKIT/Pagination/Pagination";
import {ProductFullData} from "../../../entities";

const ImageSearchComponent = () => {
    const st = useNomenclatureManager(s => s)

    const [selected, setSelected] = useState<ProductFullData | null>(null)


    const [gd1, sgd1] = useState<string[]>([])
    const [gd2, sgd2] = useState<string[]>([])

    useEffect(() => {
        if (selected != null) {
            fetch("https://www.googleapis.com/customsearch/v1?key=AIzaSyAruIk61bqPkuuYaXLIy5pdig8mC2TRi48&cx=20d382325de294c8f&searchType=image&q=" + encodeURIComponent(selected.product.name)).then((r) => {
                r.json().then(g => {
                    console.log(g)
                    sgd1(g.items?.map((h: any) => h.link))
                })
            })
            fetch("https://www.googleapis.com/customsearch/v1?key=AIzaSyAruIk61bqPkuuYaXLIy5pdig8mC2TRi48&cx=20d382325de294c8f&searchType=image&q=" + encodeURIComponent(selected.product.catalogKey)).then((r) => {

            })
        }
    }, [selected])


    return (
        <div className={s.container}>
            <div className={s.list}>
                <div className={s.table}>
                    {st.data?.products.map(n => <div
                        style={n.product.id === selected?.product.id ? {border: "1px solid red"} : {}} onClick={() => {
                        setSelected(n)
                    }} className={s.row}>
                        <div className={s.cat}>{n.product.catalogKey}</div>
                        <div className={s.name}>{n.product.name}</div>
                    </div>)}

                </div>
                <div className={s.pagination}>
                    <Pagination pageCount={st.data ? st.data.pageCount : 0} pageChange={(n) => {
                        st.setPage(n)
                    }}/>
                </div>
            </div>
            <div className={s.editor}>
                <div className={s.current}>
                    <div className={s.hdr}>
                        <div className={s.cat}>{selected?.product.catalogKey}</div>
                        <div className={s.name}>{selected?.product.name}</div>
                    </div>
                    <div className={s.gallery}>
                        {selected?.productImages.map(n => <img src={n.url}/>)}
                    </div>
                </div>
                <div className={s.g1}>
                    {gd1?.map(i => <img className={s.gimg} src={i}/>)}
                </div>
                <div className={s.g2}></div>
            </div>
        </div>
    );
};

export default ImageSearchComponent;