import React from 'react';
import s from './Import.module.scss'
import DropZone from "./DropZone";
import Select from "react-select";
import useImport from "./ImportStore";
import {Button, Input} from "@mui/material";
import AsyncSelectStorage from "../../../shared/ui/AsyncSelectSearch/AsyncSelectStorage";

const Import = () => {


    const options = [{name: "CatalogKey"}, {name: "Quantity"}, {name: "Name"}, {name: "IncomePrice"}, {name: "RetailPrice"}, {name: "Category"}, {name: "ManufBarcode"}]

    const selectState = useImport(s => s.selectState)
    const setOptionVariant = useImport(s => s.setSelectState)
    const setDataFromFile = useImport(s => s.setDataFromFile)
    const data = useImport(s => s.data)
    const columnsArray = useImport(s => s.columnsArray)
    const processUpdate = useImport(s => s.processUpdate)
    const infoUpd = useImport(s => s.infoUpd)
    const storage = useImport(s => s.storage)
    const setStorage = useImport(s => s.setStorage)
    const updateQuantity = useImport(s => s.updateQuantity)
    const processImport = useImport(s => s.processImport)
    const importExecute = useImport(s => s.importExecute)

    const incomeCof = useImport(s => s.incomeCof)
    const setIncomeCof = useImport(s => s.setIncomeCof)
    const retailCof = useImport(s => s.retailCof)
    const setRetailCof = useImport(s => s.setRetailCof)


    return (
        <div className={s.wrapper}>
            <div className={s.control}>
                <DropZone onDataRead={setDataFromFile}/>
                <Button variant={'contained'} onClick={processUpdate}>TEST</Button>
                <Button variant={'contained'} onClick={infoUpd}>UPDATE TEST</Button>
                <Input value={incomeCof} onChange={e => setIncomeCof(parseFloat(e.target.value))}/>
                <Input value={retailCof} onChange={e => setRetailCof(parseFloat(e.target.value))}/>
                <Button variant={'contained'} onClick={processImport}>IMPORT TEST</Button>
                <Button variant={'contained'} onClick={updateQuantity}>UPDATE QUANTITY</Button>
                <Button variant={'contained'} onClick={importExecute}>IMPORT EXECUTE</Button>
                <AsyncSelectStorage onSelect={setStorage} value={storage}/>
            </div>
            <div className={s.data}>

                <table style={{borderCollapse: 'collapse', width: '100%'}}>
                    <thead>
                    <tr>
                        {columnsArray.map((g, index) => {
                                return (<td key={index} style={{border: '1px solid #ddd', padding: '8px', color: "black"}}>
                                    <Select options={options} getOptionValue={n => n.name} getOptionLabel={n => n.name}
                                            isClearable onChange={n => {
                                        setOptionVariant(n as { name: string }, index)
                                    }}
                                            value={selectState.find(g => g.key === index) ? selectState.find(g => g.key === index)?.option : null}/>
                                </td>)
                            }
                        )}
                    </tr>
                    </thead>
                    <tbody>
                    {data.slice(0, 100).map((row, rowIndex) => {
                        return (
                            <tr key={rowIndex}>
                                {columnsArray.map((g, index) => {
                                        let cell = row.find(n => n.key === index + 1)
                                        return (<td key={index} style={{border: '1px solid #ddd', padding: '8px'}}>
                                            {cell?.value}
                                        </td>)
                                    }
                                )}
                            </tr>
                        )
                    })}
                    </tbody>
                </table>

            </div>

        </div>
    );
};

export default Import;