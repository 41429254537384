import React from 'react';
import {Box, Button, Container} from "@mui/material";

const SupplyInvoiceMui = () => {
    return (
        <Container sx={{height: "100vh"}}>
            <Box>
                <Button variant={"contained"} sx={{m: 1}}>Kek</Button>
            </Box>
        </Container>
    );
};

export default SupplyInvoiceMui;