import React, {ChangeEvent} from 'react';
import {CustomModal} from "../../shared/ui";
import useSetCategoryImageModal from "./SerCategoryImageModalStore";
import useTagTreeView from "../../widgets/workspace/ProductCatalog/TagTreeView/TagTreeViewStore";
import {CatalogAPI} from "../../entities";
import {useSnackbar} from "notistack";

const SetCategoryImageModal = () => {
    const s = useSetCategoryImageModal(s => s)
    const selectedTag = useTagTreeView(s => s.selectedTag)
    const treeViewTags = useTagTreeView(s => s.treeViewTags)
    const updateTag = useTagTreeView(s => s.updateTag)
    const {enqueueSnackbar} = useSnackbar()

    // загрузка изображения
    const uploadImageHandler = (e: ChangeEvent<HTMLInputElement>) => {
        console.log("upd")
        if (e.target.files && e.target.files.length) {
            const file = e.target.files[0]

            if (file.size < 7000000) {
                let formData = new FormData();
                console.log(file)
                formData.append('imageFile', file)

                CatalogAPI.setCategoryImage(formData, selectedTag).then((res) => {
                    enqueueSnackbar('Фотография загружена', {variant: 'success', autoHideDuration: 3000})
                    updateTag(res.data)
                    s.setIsOpen(false)
                }).catch((error) => {
                    enqueueSnackbar('Ошибка загрузки', {variant: 'error', autoHideDuration: 3000})
                }).finally(() => {
                })
            } else {
                console.error('Error: ', 'Файл слишком большого размера')
                enqueueSnackbar('Файл слишком большого размера', {variant: 'error', autoHideDuration: 3000})
            }
        }
    }

    const cat = treeViewTags.find(n => n.id === selectedTag)
    return (
        <CustomModal
            open={s.isOpen}
            onClose={() => {
                s.setIsOpen(false)
            }}
            onContextMenu={(event) => {
                event.preventDefault()
            }}
        >
            <div style={{
                height: "500px",
                width: "500px",
                display: "flex",
                color: "black",
                flexDirection: "column",
                gap: "10px",
                justifyContent: "space-around",
                alignItems: "center",
            }}>
                <div>
                    {cat ? cat.name : "Не обрано"}
                </div>
                {
                    cat && cat.iconUrl ?
                        <img src={cat.iconUrl} style={{height: "400px", width: "400px", border: "3px solid black"}}/> :
                        <div>Немає картинки</div>
                }

                <div>
                    <input type="file" id="file"
                           accept="image/png, image/jpeg"
                           onChange={(v) => {
                               uploadImageHandler(v)
                           }}
                    />
                </div>

            </div>
        </CustomModal>
    );
};

export default SetCategoryImageModal;