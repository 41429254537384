import React from 'react';
import s from "./HTMLParser.module.scss";

const HtmlParserPage1 = () => {
    return (
        <div className={s.page1}>
            Сбор ссылок
        </div>
    );
};

export default HtmlParserPage1;