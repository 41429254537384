import React from 'react';
import s from './ProductCardManager.module.scss'
import {ProductCatalogTable} from "../../../widgets";
import ProductCatalogTablePaggination
    from "../../../widgets/workspace/ProductCatalog/ProductCatalogTable/ProductCatalogTablePaggination";

const ProductCardManager = () => {

    return (
        <div className={s.wrapper}>
            <div className={s.table}><ProductCatalogTable/></div>
            <div className={s.table_pag}><ProductCatalogTablePaggination/></div>
        </div>
    );
};

export default ProductCardManager;