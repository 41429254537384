export const BikeShopPaths = {
    COMMON: {
        LOGIN: '/login',
        REGISTRATION: '/registration',
        ERROR404: 'error404', // надо сделать
    },

    SHOP: {
        //HOME: '/shop',
        CATALOG: '/shop/catalog',
        PRODUCT: '/shop/catalog/:productId',
        PROFILE: '/shop/profile',
        ORDER: '/shop/order',
        PUBLIC_OFFER: '/public-offer',
        ABOUT_US: '/about-us',
        DELIVERY_INFO: '/delivery'
    },


    WORKSPACE: {
        MAIN_PAGE: '/',
        SERVICE: '/service',
        CASHBOX: '/cashbox',
        PRODUCT_CATALOG: '/product-catalog',
        WORK_CATALOG: '/work-catalog',
        PROFILE: '/profile',
        ADMIN: '/admin',
        NOMENCLATURE: '/nomenclature',
        CRM: '/crm',
        ORDERS: '/orders',
        SCHEDULE: '/schedule',
        CARD_CATALOG: '/card-catalog',
        PRODUCT_MANAGER: '/product-manager',
        HTML_PARSER: '/parser',
        CLIENT_MANAGER: '/cm',
        DRIVE: '/drive',
        IMPORT: '/import',

        ARRIVAL_OF_PRODUCTS: '/arrival-of-products',
        SUPPLY_INVOICE: '/supply-invoice',
        OUTCOME_ACT: '/outcome',
        INVENTARIZATION: '/inventarization',
        STORAGE_PRODUCTS_TRANSFER: '/storage-products-transfer',

        SHOP_CHECK: '/shop-check',
        WORK_CHECK: '/work-check',
        WORK_ACT: '/work-act',
        GET_STUFF_TO_SERVICE_ACT: '/get-stuff-to-service-act',
        GET_STUFF_FROM_SERVICE_ACT: '/get-stuff-from-service-act',
    },
}