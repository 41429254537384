import React from 'react'
import s from "./EditProductCardDescriptionFull.module.scss"
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css'
import useEditProductCardModal from "./EditProductCardModalStore";
import {CKEditor} from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';

export const EditProductCardDescriptionFull = () => {

    const setDescription = useEditProductCardModal(s => s.setDescription)
    const currentProduct = useEditProductCardModal(s => s.currentProduct)

    const handleEditorChange = (event: any, editor: any) => {
        const data = editor.getData();
        setDescription(data);
    };

    return (
        <div className={s.fullEditor}>
            <div className={s.descriptionEditor_title}>
                Детальное описание товара:
            </div>
            <div className={s.descriptionEditor_editorTextarea}>
                <CKEditor
                    editor={ClassicEditor}
                    data={currentProduct.productCard.description}
                    onChange={handleEditorChange}
                />
            </div>
        </div>
    )
}