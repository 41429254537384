import {create} from "zustand"
import {devtools, persist} from "zustand/middleware"
import {immer} from "zustand/middleware/immer"
import {CreateQuantityUnit, EntitiesAPI, GetQuantityUnitResponse, UpdateQuantityUnit} from "../../../entities"
import {QuantityUnitGroup} from "../../../entities/entities/QuantityUnit/QuantityUnitGroup";

interface CreateQuantityUnitModalStore {
    updateDisplayedUnits: () => void,
    displayedUnits: GetQuantityUnitResponse[]

    openQuantityUnitModal: boolean
    setOpenCreateQuantityUnitModal: (value: boolean) => void

    selectedGroupId: number
    setSelectedGroupId: (id: number) => void

    createQuantityUnitGroup: (name: string) => void
    quantityUnitGroups: QuantityUnitGroup[]
    getQuantityUnitGroups: () => void

    quantityUnits: GetQuantityUnitResponse[]
    currentQuantityUnit: GetQuantityUnitResponse | null
    setCurrentQuantityUnit: (quantityUnit: GetQuantityUnitResponse | null) => void

    getQuantityUnits: () => void
    addQuantityUnit: (data: CreateQuantityUnit) => any
    updateQuantityUnit: (updateData: UpdateQuantityUnit) => any
}

const useCreateQuantityUnitModal = create<CreateQuantityUnitModalStore>()(persist(devtools(immer((set, get) => ({
    updateDisplayedUnits: () => {
        set({displayedUnits: get().quantityUnits.filter(n => n.groupId === get().selectedGroupId)})
    },
    displayedUnits: [],
    createQuantityUnitGroup: (name) => {
        EntitiesAPI.QuantityUnit.createGroup(name).then(res => {
            set(state => {
                state.quantityUnitGroups.push(res.data)
            })
        })
    },
    setSelectedGroupId: v => {
        set({selectedGroupId: v})
        get().updateDisplayedUnits()
    },
    selectedGroupId: 0,
    getQuantityUnitGroups: () => {
        EntitiesAPI.QuantityUnit.getQuantityUnitGroups().then(res => {
            set(state => {
                state.quantityUnitGroups = res.data
            })
        })
    },
    quantityUnitGroups: [],
    openQuantityUnitModal: false,
    setOpenCreateQuantityUnitModal: (value: boolean) => set({openQuantityUnitModal: value}),
    isLoading: false,
    errorStatus: 'default',

    currentQuantityUnit: null,
    setCurrentQuantityUnit: (quantityUnit) => {
        set({currentQuantityUnit: quantityUnit})
    },
    quantityUnits: [],
    getQuantityUnits: () => {
        EntitiesAPI.QuantityUnit.getQuantityUnits().then(res => {
            set(state => {
                state.quantityUnits = res.data
            })
        })
        get().updateDisplayedUnits()
    },
    addQuantityUnit: (data) => {
        EntitiesAPI.QuantityUnit.addQuantityUnit(data).then((res: any) => {
            set(state => {
                state.quantityUnits.push(res.data)
            })
            get().updateDisplayedUnits()
        })
    },
    updateQuantityUnit: (updateData) => {
        EntitiesAPI.QuantityUnit.updateQuantityUnit(updateData).then((res: any) => {
            EntitiesAPI.QuantityUnit.getQuantityUnits().then(res => {
                set(state => {
                    state.quantityUnits = res.data
                    state.currentQuantityUnit = null
                })
                get().updateDisplayedUnits()
            })
        })
    },
}))), {
    name: "createQuantityUnitModal",
    version: 1
}));

export default useCreateQuantityUnitModal;