import React, {memo, ReactElement, useEffect} from 'react';
import {useApp} from "../../../entities/globalStore/AppStore";
import {$api} from "../../../shared";
import {AxiosError} from "axios";
import createAuthRefreshInterceptor from "axios-auth-refresh";
import {AuthAPI, useAuth} from "../../../entities";
import {BikeShopPaths} from "../../routes/paths";
import {useSnackbar} from "notistack";
import {CustomError} from "../../../entities/errors/workspaceErrors";

const HttpProvider = memo((p: { children: ReactElement }) => {

    const {enqueueSnackbar} = useSnackbar()

    const setIsLoading = useApp(n => n.setIsLoading)
    const setLastError = useApp(n => n.setLastError)
    const createAgentHubConnection = useApp(n => n.createAgentHubConnection)
    const AgentHubStartConnection = useApp(n => n.AgentHubStartConnection)
    const setNavTo = useApp(n => n.setNavTo)
    const logout = useAuth(n => n.logout);
    const triggerActivity = useAuth(s => s.triggerActivity)

    useEffect(() => {
        const handleUserAction = (event: Event) => {
            triggerActivity()
        };

        // Add event listeners to detect user actions
        //window.addEventListener('mousemove', handleUserAction);
        window.addEventListener('keydown', handleUserAction);
        window.addEventListener('click', handleUserAction);

        // Clean up the event listeners on component unmount
        return () => {
            //window.removeEventListener('mousemove', handleUserAction);
            window.removeEventListener('keydown', handleUserAction);
            window.removeEventListener('click', handleUserAction);
        };
    }, []);

    // Function that will be called to refresh authorization
    const refreshAuthLogic = (failedRequest: any) =>
        AuthAPI.refresh().then((tokenRefreshResponse) => {
            localStorage.setItem('accessToken', tokenRefreshResponse.data.accessToken)
            return Promise.resolve();
        }).catch(() => {
            logout().finally(() => {
                setNavTo(BikeShopPaths.COMMON.LOGIN)
                enqueueSnackbar('Сессия устарела, повторите вход', {variant: 'warning', autoHideDuration: 3000})
            })
            return Promise.reject(failedRequest);
        });

    createAgentHubConnection()
    AgentHubStartConnection(() => {
    }, () => {
    })

    $api.interceptors.request.use(function (config) {
        setIsLoading(true)
        config.headers.Authorization = `Bearer ${localStorage.getItem('accessToken')}`;
        return config;
    }, function (error) {
        // Do something with request error
        return Promise.reject(error);
    });

// Instantiate the interceptor
    createAuthRefreshInterceptor($api, refreshAuthLogic);

    $api.interceptors.response.use(function (response) {
        setIsLoading(false)
        return response;
    }, (error: AxiosError<CustomError>) => {
        setIsLoading(false);
        console.warn('Ошибка:', error.response?.data)
        setLastError(error.response?.data ? error.response?.data : null)
        return Promise.reject(error);
    });

    return p.children;
});

export default HttpProvider;