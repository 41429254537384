import {AxiosResponse} from "axios";
import {$api} from "../../shared";
import {DriveFile} from "../entities/Drive/DriveFile";

export const DriveAPI = {
    GetFoldersTree(): Promise<AxiosResponse<DriveFile[]>> {
        return (
            $api.get<DriveFile[]>(`/drive/folderstree`)
        )
    },
    GetFilesByFolder(Id: string): Promise<AxiosResponse<DriveFile[]>> {
        return (
            $api.get<DriveFile[]>(`/drive/filesbyfolder?FolderId=${Id}`)
        )
    },
    UploadFile(file: any, Parent: string | null): Promise<AxiosResponse<DriveFile>> {
        const formData = new FormData();
        formData.append('File', file);
        return (
            $api.post<DriveFile>(`/drive/upload?${Parent ? 'Parent=' + Parent : ''}`, formData)
        )
    },
    GrantWrite(email: string, id: string): Promise<AxiosResponse> {
        return (
            $api.post(`/drive/grantwrite?Email=${email}&FileId=${id}`)
        )
    },
    CreateDoc(Name: string, Parent: string): Promise<AxiosResponse<DriveFile>> {
        return (
            $api.post<DriveFile>(`/drive/createdoc?Name=${Name}&Parent=${Parent}`)
        )
    },
    CreateSheet(Name: string, Parent: string): Promise<AxiosResponse<DriveFile>> {
        return (
            $api.post<DriveFile>(`/drive/createsheet?Name=${Name}&Parent=${Parent}`)
        )
    },
    CreateFolder(Name: string, Parent: string): Promise<AxiosResponse<DriveFile>> {
        return (
            $api.post<DriveFile>(`/drive/createfolder?Name=${Name}&Parent=${Parent}`)
        )
    },
    DeleteFile(FileId: string): Promise<AxiosResponse<DriveFile>> {
        return (
            $api.post<DriveFile>(`/drive/deletefile?FileId=${FileId}`)
        )
    },
}