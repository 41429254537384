import React, {useEffect, useMemo, useState} from 'react';
import {useAuth} from "../../../entities";

const ClockCustom = () => {

    const isTimerOn = useAuth(s => s.isTimerOn)
    const [currentTime, setCurrentTime] = useState("0")
    const st = useAuth(s => s)
    useEffect(() => {

        let t = localStorage.getItem(`activeClock:${st.user?.id}`)
        if (t === null) t = "0"
        setCurrentTime(t)
    }, [])

    useEffect(() => {
        const timer = setInterval(() => {
            if (isTimerOn) {

                setCurrentTime(prevTime => {
                    let newTime = parseInt(prevTime);
                    newTime += 1
                    localStorage.setItem(`activeClock:${st.user?.id}`, newTime.toString())
                    return newTime.toString();
                });
            }

        }, 1000)

        return () => {
            clearInterval(timer)
        }
    }, [isTimerOn])

    const time = useMemo(() => {
        const secs = parseInt(currentTime)
        const hours = Math.floor(secs / 3600)
        const minutes = Math.floor(secs % 3600 / 60)

        return `${hours}ч.:${minutes < 10 ? `0${minutes}` : minutes}мин.`
    }, [currentTime])

    return <>{"Активность - " + time}</>
};

export default ClockCustom;