import React from 'react';
import s from './CRMClientConversions.module.scss'
import {UserConversion} from "../../../../entities/entities/CRM/UserConversion";
import {DateConvertor} from "../../../../shared/utils/DateUtilities/DateConvertor";
import {useCurrency} from "../../../../entities";

const CrmClientConversions = (p: { conversions: UserConversion[] }) => {
    const calc = useCurrency(s => s.calc)
    return (
        <div className={s.wrapper}>
            {p.conversions.map(c => <div className={s.item}>
                <div className={s.type}>{c.conversionType}</div>
                <div className={s.date}>{DateConvertor.GetDate(c.date)}</div>
                <div className={s.amount}>{calc(c.total)}</div>
            </div>)}
        </div>
    );
};

export default CrmClientConversions;