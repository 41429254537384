import React, {useEffect, useState} from 'react';
import {CustomInput, CustomModal} from "../../../shared/ui";
import {useProductOptionsManagerModal} from "./ProductOptionsManagerModalStore";
import s from './ProductOptionsManagerModal.module.scss'
import {Button} from "@mui/material";
import {useSnackbar} from "notistack";

const ProductOptionsManagerModal = () => {
    const open = useProductOptionsManagerModal(s => s.open)
    const setOpen = useProductOptionsManagerModal(s => s.setOpen)

    const loadOptionsWithVariants = useProductOptionsManagerModal(s => s.loadOptionsWithVariants)
    const optionsWithVariants = useProductOptionsManagerModal(s => s.optionsWithVariants)

    const selectedOption = useProductOptionsManagerModal(s => s.selectedOption)
    const setSelectedOption = useProductOptionsManagerModal(s => s.setSelectedOption)

    const addVariantOption = useProductOptionsManagerModal(s => s.addVariantOption)
    const createOption = useProductOptionsManagerModal(s => s.createOption)

    const [varInput, setVarInput] = useState('')
    const [optInput, setOptInput] = useState('')
    const [optVarInput, setOptVarInput] = useState('')
    const {enqueueSnackbar} = useSnackbar()

    useEffect(() => {
        loadOptionsWithVariants()
    }, [])

    return (
        <CustomModal
            open={open}
            onClose={() => {
                setOpen(false)
            }}
        >
            <div className={s.wrapper}>

                <div className={s.options}>
                    {
                        optionsWithVariants.map(n =>
                            <div className={s.item}
                                 style={selectedOption?.id === n.id ? {
                                     backgroundColor: 'white',
                                     border: 'black 1px solid',
                                     fontWeight: '600'
                                 } : {}} onClick={() => {
                                setSelectedOption(n)
                            }} key={n.id}>
                                {n.name}
                            </div>)
                    }
                </div>
                <div className={s.variant_control}>
                    {
                        selectedOption === null ? <div className={s.null}>Оберіть опцію</div> :
                            <>
                                <div className={s.variants}>
                                    {
                                        selectedOption?.optionVariants.map(n =>
                                            <div className={s.variant} key={n.id}>
                                                {n.name}
                                            </div>)
                                    }
                                </div>
                                <div className={s.addvariant}>
                                    <CustomInput placeholder={'Назва нового варіанта'} value={varInput}
                                                 onChange={n => setVarInput(n.target.value)}/>
                                    <Button variant={"contained"} disabled={varInput.length < 1}
                                            onClick={() => addVariantOption(varInput, () => {
                                                setVarInput('')
                                                enqueueSnackbar('Варіант додано', {
                                                    variant: 'success',
                                                    autoHideDuration: 3000
                                                })
                                            })}>Додати новий варіант
                                        опції</Button>
                                </div>
                                <div className={s.addoption}>
                                    <CustomInput placeholder={'Назва нової опції'} value={optInput}
                                                 onChange={n => setOptInput(n.target.value)}/>
                                    <CustomInput placeholder={'Назва першого варіанту нової опції'} value={optVarInput}
                                                 onChange={n => setOptVarInput(n.target.value)}/>
                                    <Button variant={"contained"}
                                            disabled={optInput.length < 1 || optVarInput.length < 1}
                                            onClick={() => createOption(optInput, optVarInput, () => {
                                                setOptInput('')
                                                setOptVarInput('')
                                                enqueueSnackbar('Опцію створено', {
                                                    variant: 'success',
                                                    autoHideDuration: 3000
                                                })
                                            })}>Створити нову опцію
                                        опції</Button>
                                </div>
                            </>
                    }

                </div>
            </div>
        </CustomModal>
    );
};

export default ProductOptionsManagerModal;