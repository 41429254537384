import React, {useState} from 'react';
import {Button, CustomModal, CustomTextarea} from "../../../../shared/ui";
import s from './CRMClientTasks.module.scss'
import {AsyncSelectSearchEmployee} from "../../../../shared/ui/AsyncSelectSearch/AsyncSelectSearchEmployee";
import {LocalStorage} from "../../../../entities";
import Select from "react-select";
import {AddTaskStepCRMRequest} from "../../../../entities/DataTransferObjects/requests/AddTaskStepCRMRequest";
import {CrmAPI} from "../../../../entities/api/CrmAPI";
import {useSnackbar} from "notistack";
import {UserCRMTask} from "../../../../entities/entities/CRM/UserCRMTask";
import {UserWithRoles} from "../../../../entities/models/Auth/UserWithRoles";
import {UserCRMTaskWithSteps} from "../../../../entities/DataTransferObjects/responses/UserCRMTaskWithSteps";

const CrmClientTaskAddStepModal = (p: { open: boolean, setOpen: (v: boolean) => void, task: UserCRMTask, onTaskUpdate: (v: UserCRMTaskWithSteps) => void }) => {

    const {enqueueSnackbar} = useSnackbar()

    const [info, setInfo] = useState("")
    const [selStat, setSelStat] = useState<{ name: string, label: string } | null>(null)
    const [manager, setManager] = useState<UserWithRoles | null | undefined>(null)

    const options: { name: string, label: string }[] = [
        {name: "WaitingClient", label: "Очікує клієнта"},
        {name: "WaitingEmployee", label: "Очікує менеджера"},
        {name: "Closed", label: "Закрито"},
    ]

    const handleCreateStep = () => {
        if (info.length < 4) {
            enqueueSnackbar('Заповніть інформацію ', {variant: 'warning', autoHideDuration: 3000})
            return
        }
        let data: AddTaskStepCRMRequest = {
            taskId: p.task.id,
            info: info,
            managerId: manager ? manager.user.id : undefined,
            statusUpdate: selStat ? selStat.name : undefined
        }
        CrmAPI.AddTaskStep(data).then((r) => {
            p.onTaskUpdate(r.data)
            enqueueSnackbar('Крок додано', {variant: 'success', autoHideDuration: 3000})
            setInfo('')
            setSelStat(null)
            setManager(null)
            p.setOpen(false)
        })
    }

    return (
        <CustomModal
            open={p.open}
            onClose={() => {
                p.setOpen(false)
            }}
        >
            <div className={s.add_step}>
                <div className={s.row1}>
                    <div className={s.manager}>
                        Змінити менеджера (якщо треба):
                        <AsyncSelectSearchEmployee onSelect={() => {
                        }} shopId={parseFloat(LocalStorage.shopId()!)} setValue={setManager}/>
                    </div>
                    <div className={s.status}>
                        Змінити статус (якщо треба):
                        <Select options={options} getOptionLabel={n => n.label} getOptionValue={n => n.label}
                                onChange={setSelStat}/>
                    </div>
                </div>
                <div className={s.info}>
                    <CustomTextarea placeholder={'Опис кроку'} value={info} onChange={(e) => setInfo(e.target.value)}/>
                </div>
                <div className={s.butns}>
                    <Button onClick={() => p.setOpen(false)}>Відмінити</Button>
                    <Button onClick={() => handleCreateStep()}>Додати крок до завдання</Button>
                </div>
            </div>
        </CustomModal>
    );
};

export default CrmClientTaskAddStepModal;