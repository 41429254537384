import React, {useEffect} from 'react';
import s from './CardCatalog.module.scss'
import {ProductCatalogTable, TagTreeView} from "../../../widgets";
import useCardCatalogStore from "./CardCatalogStore";
import ProductCatalogTableHeader
    from "../../../widgets/workspace/ProductCatalog/ProductCatalogTable/ProductCatalogTableHeader";
import ProductCatalogTablePaggination
    from "../../../widgets/workspace/ProductCatalog/ProductCatalogTable/ProductCatalogTablePaggination";
import CardCatalogFilters from "./CartCatalogFilters/CardCatalogFilters";
import {CreateProductModal} from "../../../widgets/workspace/ProductCatalog/CreateProductModal/CreateProductModal";
import {useSnackbar} from "notistack";
import {Checkbox, FormControlLabel} from "@mui/material";

const CardCatalog = () => {
    const st = useCardCatalogStore(s => s)
    const {enqueueSnackbar} = useSnackbar()

    useEffect(() => {
        st.getStorages()
        st.setQSort(false)
    }, [])

    return (
        <div className={s.wrapper} onContextMenu={e => e.preventDefault()}>
            <CreateProductModal/>
            <div className={s.trees}>
                <div className={s.filters}><CardCatalogFilters/></div>
                <div className={s.categories}>
                    <TagTreeView onNodeClick={(n) => {
                        console.log(st.qSort)
                        if (!st.qSort) st.setLastCategoryId(n.id)
                    }} onNodeContext={n => {
                        st.setLastCategoryId(n.id)
                    }} onNodeDoubleClick={(n) => {
                        if (st.qSort) st.sortProduct(n.id, () => {
                            enqueueSnackbar('Товар перемещен', {variant: 'success', autoHideDuration: 3000})
                        })
                    }}/>
                    <FormControlLabel style={{paddingLeft: "15px"}}
                                      control={<Checkbox value={st.qSort} onChange={v => {
                                          st.setQSort(v.target.checked)
                                      }}/>}
                                      label="Режим швидкого сортування"/>
                </div>
            </div>
            <div className={s.table}>
                <div className={s.table_header}><ProductCatalogTableHeader/></div>
                <div className={s.table_data}><ProductCatalogTable/></div>
                <div className={s.table_pag}><ProductCatalogTablePaggination/></div>
            </div>
        </div>
    );
};

export default CardCatalog;