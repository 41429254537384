import React, {memo, useState} from 'react';
import s from "./HTMLParser.module.scss";
import Select from "react-select";
import {Button, CustomInput} from "../../../shared/ui";
import {ParseAPI} from "../../../entities/api/ParseAPI";
import SyntaxHighlighter from 'react-syntax-highlighter';
import {a11yDark} from 'react-syntax-highlighter/dist/esm/styles/hljs';
import {ParseScript} from "./ParseScript";

const HtmlParserPage2 = memo(() => {
    const [text, setText] = useState("")
    const [gs, sgs] = useState("")
    const [gf, sgf] = useState("")
    const [link, setLink] = useState("")
    const opt = [{name: "Name"}, {name: "CatalogKey"}, {name: "Images"}, {name: "Description"}]
    return (
        <div className={s.page2}>
            <div className={s.gran}>
                <div className={s.gran_select}>
                    <Select options={opt} getOptionLabel={n => n.name}/>
                </div>
                <Button>Сохранить изменения</Button>
                <Button>Отмена</Button>
            </div>
            <div className={s.gran_desc}>
                <CustomInput value={gs} onChange={e => sgs(e.target.value)} placeholder={"Початок границі"}/>
                <CustomInput value={gf} onChange={e => sgf(e.target.value)} placeholder={"Кінець границі"}/>
                <Button onClick={() => {
                    console.log(ParseScript.ParseImg(text, gs, gf))
                }}>TryParse</Button>
            </div>
            <div className={s.link}>
                <CustomInput value={link} onChange={(event) => {
                    setLink(event.target.value)
                }} placeholder={"Link"}/>
                <Button onClick={async () => {
                    ParseAPI.Download(link).then(r => {
                        setText(r.data)
                    })
                }}>Download Page</Button>
            </div>
            <div className={s.page_code}>
                <SyntaxHighlighter wrapLongLines wrapLines language="xml"
                                   style={a11yDark}>
                    {text}
                </SyntaxHighlighter>
            </div>
        </div>
    );
});

export default HtmlParserPage2;