import {create} from "zustand"
import {devtools, persist} from "zustand/middleware"
import {immer} from "zustand/middleware/immer"
import {EntitiesAPI, ProductCardAPI, ProductFullData} from '../../../entities'
import {ProductOptionsWithVariants} from "./models/ProductOptionsWithVariants"
import {UpdateProductCardFormModel} from "./models/UpdateProductCardFormModel"
import {ProductOptionVariantBind} from "entities";

interface EditProductCardModalStore {
    openEditProductCardModal: boolean
    setOpenEditProductCardModal: (value: boolean) => void

    currentProduct: ProductFullData
    setCurrentProduct: (v: ProductFullData) => void
    setOptions: (v: ProductOptionVariantBind[]) => void
    setStatus: (v: string) => void
    setDescription: (v: string) => void
    removeProductBind: (id: number) => void
    addProductBind: (p: ProductFullData) => void
    getProductCard: (productId: number) => void
    updateProductCard: (s: (p: ProductFullData) => void) => void

    allOptions: ProductOptionsWithVariants[]
    getAllOptions: (os?: () => void) => void

    getProductBindIndex: (id: number) => number
    selectedBindedProductId: number
    setSelectedBindedProductId: (v: number) => void

}

const useEditProductCardModal = create<EditProductCardModalStore>()(persist(devtools(immer((set, get) => ({
    setCurrentProduct: (v) => {
        set({currentProduct: v})
        set({selectedBindedProductId: 0})
    },
    setOptions: (v) => {
        set({
            currentProduct: {...get().currentProduct, productOptions: v}
        })
    },
    removeProductBind: (v) => {
        set(state => {
            state.currentProduct.bindedProducts = state.currentProduct.bindedProducts.filter(n => n.id != v)
        })
        set(state => {
            state.currentProduct.productImages = state.currentProduct.productImages.filter(n => n.productId != v)
        })
        set(state => {
            state.currentProduct.productOptions = state.currentProduct.productOptions.filter(n => n.productId != v)
        })
    },
    setDescription: (v) => {
        set(state => {
            state.currentProduct.productCard.description = v
        })
    },
    setStatus: (v) => {
        set(state => {
            state.currentProduct.product.checkStatus = v
        })
    },
    addProductBind: (p) => {
        set({
            currentProduct: {
                ...get().currentProduct,
                bindedProducts: [...get().currentProduct.bindedProducts, p.product]
            }
        })
        set(state => {
            state.currentProduct.productOptions.push(...p.productOptions)
        })
        set(state => {
            state.currentProduct.productImages.push(...p.productImages)
        })

    },
    setSelectedBindedProductId: (v) => set({selectedBindedProductId: v}),
    selectedBindedProductId: 0,
    getProductBindIndex: (id) => {
        return get().currentProduct.bindedProducts.findIndex(n => n.id === id)
    },

    openEditProductCardModal: false,
    setOpenEditProductCardModal: (value) => set({openEditProductCardModal: value}),

    currentProduct: {} as ProductFullData,
    getProductCard: (productId: number) => {
        ProductCardAPI.getProductCardById(productId).then(res => {
            set(state => {
                state.currentProduct = res.data
            })
            set({openEditProductCardModal: true})
        })
    },
    updateProductCard: (s) => {
        let buf = get().currentProduct
        let data: UpdateProductCardFormModel = {
            id: buf.product.id,
            checkStatus: buf.product.checkStatus,
            productCard: {description: buf.productCard.description, shortDescription: buf.productCard.descriptionShort},
            productOptions: buf.productOptions,
            bindedProducts: buf.bindedProducts
        }
        ProductCardAPI.updateProductCard(data).then((res) => {
            set({currentProduct: res.data})
            s(res.data)
        })
    },

    allOptions: [],
    getAllOptions: (os) => {
        EntitiesAPI.Option.getOptions().then(res => {
            set(state => {
                state.allOptions = res.data
            })

            if (os) os()
        })
    },
}))), {
    name: "editProductCardModal",
    version: 1
}));

export default useEditProductCardModal;