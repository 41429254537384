import {create} from "zustand"
import {devtools, persist} from "zustand/middleware"
import {immer} from "zustand/middleware/immer"
import {BillWithProducts, FinancialInteractionAPI, LocalStorage, PaymentData, Product, User} from '../../../entities'
import {NewBillDTO} from "./models/NewBillDTO"
import {BillProductsFormModel} from "../../../entities/models/BillProductsFormModel";

interface CashboxStore {
    products: BillProductsFormModel[]
    user: User | null
    setUser: (user: User | null) => void

    setProducts: (value: BillProductsFormModel[]) => void
    clearProducts: () => void
    addProduct: (value: Product) => void
    paymentHandler: (data: PaymentData, onSuccess: (r: BillWithProducts) => void) => void

    sum: number
    setSum: () => void
}

const useCashboxStore = create<CashboxStore>()(persist(devtools(immer((set, get) => ({
    products: [],

    user: null,
    setUser: (user) => set({
        user: user
    }),

    clearProducts: () => {
        set({products: []})
    },

    setProducts: (value) => {
        let data = value.filter(n => n.quantity != 0)
        data.forEach(n => n.total = n.price * n.quantity - 0)
        set({products: data})
        get().setSum()
    },
    addProduct: (n) => {
        let exist = false

        let data = get().products.map(p => {
            if (n.id === p.productId) {
                exist = true;
                p.quantity++;
                return p
            } else {
                return p
            }
        })

        if (!exist) {
            let newProd: BillProductsFormModel = {
                productId: n.id,
                name: n.name,
                catalogKey: n.catalogKey,
                serialNumber: '',
                description: '',
                quantity: 1,
                currencyId: 1,
                quantityUnitName: n.quantityUnitName,
                currencySymbol: LocalStorage.currency.symbol()!,
                price: n.retailPrice,
                discountId: 0,
                total: n.retailPrice
            }
            data.push(newProd)
        }

        get().setProducts(data);
    },
    paymentHandler: (data, onSuccess) => {
        let res: NewBillDTO = {
            payment: data,
            shopId: LocalStorage.shopId()!,
            clientId: get().user?.id,
            userId: LocalStorage.userId()!,
            description: "",
            products: get().products.map((p) => {
                return (
                    {
                        productId: p.productId,
                        quantity: p.quantity,
                        discountId: p.discountId,
                        description: p.description
                    }
                )
            })
        }

        console.log(res)
        //onSuccess({} as BillWithProducts)
        FinancialInteractionAPI.NewBill.create(res).then((r) => {
            get().setUser(null)
            onSuccess(r.data)
        })
    },

    sum: 0,
    setSum: () => {
        let sum: number = 0
        get().products.forEach(n => {
            sum += (n.quantity * n.price - 0)
        })

        set({sum: sum})
    },
}))), {
    name: "cashboxStore",
    version: 1
}));

export default useCashboxStore;