import React, {memo, useEffect, useState} from 'react';
import {EntitiesAPI} from "../../../entities";
import {CreateStorageResponse} from "../../../entities/DataTransferObjects/responses/StorageResponse";
import Select from "react-select";

const AsyncSelectStorage = memo((props: { onSelect: (value: CreateStorageResponse | null) => void, value: CreateStorageResponse | null }) => {

    const [options, setOptions] = useState<CreateStorageResponse[]>([])

    useEffect(() => {
        EntitiesAPI.Storage.getStorages().then((resp) => {
            setOptions(resp.data)
        })
    }, [])

    return (
        <div style={{color: 'black', width: "300px"}}>
            <Select
                isClearable
                value={props.value}
                options={options}
                onChange={(r: CreateStorageResponse | null) => {
                    props.onSelect(r)
                    console.log('selected', r)
                }}
                getOptionLabel={label => label!.id + ' | ' + label!.name}
                getOptionValue={value => value!.name}
                placeholder={'Поиск'}
                noOptionsMessage={() => 'Услуга не найдена'}
            />
        </div>
    );
});

export default AsyncSelectStorage;