import {User} from "../../../entities";

export function GetShorterFIOFromUser(user: User | null | undefined): string {
    if (user === null || user === undefined) return 'Немає користувача'
    let FIO = ''
    if (user.lastName != null) FIO += user.lastName + ' '
    else if (user.firstName != null) {
        FIO += user.firstName + ' '
        if (user.patronymic != null) {
            FIO += user.patronymic[0] + '.';
            return FIO
        }
    } else {
        FIO += user.patronymic;
        return FIO
    }
    if (user.firstName != null) FIO += user.firstName[0] + '.'
    if (user.patronymic != null) FIO += ' ' + user.patronymic[0] + '.'
    return FIO
}