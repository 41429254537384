import React, {Suspense} from "react"
import './styles/index.scss'
import {RouterProvider} from "react-router-dom"
import {SnackbarProvider} from "notistack"
import Buttons from "../widgets/workspace/AdminMenu/ui/Buttons"
import {routes} from './routes/routes'
import HttpProvider from "./providers/HTTPProvider/HTTPProvider";
import {DndProvider} from "react-dnd";
import {HTML5Backend} from 'react-dnd-html5-backend';

export const App = () => {

    return (
        <div className={`app`}>
            <DndProvider backend={HTML5Backend}>
                <SnackbarProvider>
                    <HttpProvider>
                        <Suspense>
                            <RouterProvider router={routes}/>
                        </Suspense>
                    </HttpProvider>
                </SnackbarProvider>
            </DndProvider>
            <Buttons off={true}/>
        </div>
    )
}