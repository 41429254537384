import React from 'react';
import s from "./OrderManager.module.scss";
import {OrderWithProducts} from "../../entities/entities/Order/OrderWithProducts";
import useOrderManager from "./OrderManagerStore";
import CallButton from "../../shared/ui/CallButton/CallButton";
import {GetShorterFIOFromUser} from "../../shared/utils/UserUtilities/GetShortFIOFromUser";

const OrderHovered = (props: { order: OrderWithProducts }) => {
    const confirm = useOrderManager(s => s.confirm)
    const collected = useOrderManager(s => s.collected)
    const toCollection = useOrderManager(s => s.toCollection)
    const shipped = useOrderManager(s => s.shipped)
    const delivered = useOrderManager(s => s.delivered)
    const setPayModal = useOrderManager(s => s.setPayModal)
    const setCrmModal = useOrderManager(s => s.setCrmModal)
    const setCancelModal = useOrderManager(s => s.setCancelModal)
    const toLogistic = useOrderManager(s => s.toLogistic)
    const setConfirmIssueModal = useOrderManager(s => s.setConfirmIssueModal)


    let mainButton: JSX.Element = <div></div>
    let subButton: JSX.Element = <div></div>

    if (props.order.order.orderStatus === "Created") {
        mainButton = (
            <div className={s.order_third_row_action_main} onClick={() => {
                confirm(props.order.order.id)
            }}>
                Підтвердити
            </div>)

        subButton = (
            <div className={s.order_third_row_action_third} onClick={() => setCancelModal(true)}>
                Скасувати
            </div>)
    }

    if (props.order.order.orderStatus === "WaitingForPayment") {
        mainButton = (
            <div className={s.order_third_row_action_main} onClick={() => {
                setPayModal(true)
            }}>
                Оплатити
            </div>)

        subButton = (
            <div className={s.order_third_row_action_third} onClick={() => setCancelModal(true)}>
                Скасувати
            </div>)
    }

    if (props.order.order.orderStatus === "WaitingForCollection") {
        mainButton = (
            <div className={s.order_third_row_action_main} onClick={() => {
                collected(props.order.order.id)
            }}>
                Укомплектовано
            </div>)

        subButton = (
            <div className={s.order_third_row_action_second} onClick={() => {
                toLogistic(props.order.order.id)
            }}>
                Чекаємо логістику
            </div>)
    }

    if (props.order.order.orderStatus === "WaitingLogistic") {
        mainButton = (
            <div className={s.order_third_row_action_main} onClick={() => {
                toCollection(props.order.order.id)
            }}>
                До збірки
            </div>)

        subButton = (
            <div className={s.order_third_row_action_third} onClick={() => setCancelModal(true)}>
                Скасувати
            </div>)
    }

    if (props.order.order.orderStatus === "ReadyInShop") {
        mainButton = (
            <div className={s.order_third_row_action_main} onClick={() => {
                if (!props.order.order.isPayed) setPayModal(true)
                else setConfirmIssueModal(true)
            }}>
                Видати
            </div>)

        subButton = (
            <div className={s.order_third_row_action_third} onClick={() => setCancelModal(true)}>
                Скасувати
            </div>)
    }

    if (props.order.order.orderStatus === "WaitingForShipping") {
        mainButton = (
            <div className={s.order_third_row_action_main} onClick={() => {
                shipped(props.order.order.id)
            }}>
                Відправлено
            </div>)

        subButton = (
            <div className={s.order_third_row_action_second} style={{
                pointerEvents: 'none',
                opacity: '0.5'
            }}>
                Створити ТТН
            </div>)
    }

    if (props.order.order.orderStatus === "Shipped") {
        mainButton = (
            <div className={s.order_third_row_action_main} onClick={() => {
                delivered(props.order.order.id)
            }}>
                Доставлено
            </div>)

        subButton = (
            <div className={s.order_third_row_action_second} onClick={() => {
                //delivered(props.order.order.id)
            }} style={{
                pointerEvents: 'none',
                opacity: '0.5'
            }}>
                Повернення
            </div>)
    }

    return (
        <div className={s.order_third_row}>
            <div className={s.order_third_row_crm}>
                <div className={s.order_third_row_crm_actions}>
                    <div className={s.action_row1}>
                        {GetShorterFIOFromUser(props.order.client.user)}
                    </div>
                    <div className={s.action_row2}>
                        <CallButton clientId={props.order.order.clientId} size={40}/>
                        <div className={s.crm} onClick={() => setCrmModal(true)}>CRM</div>
                    </div>
                </div>
                <div className={s.order_third_row_crm_last}></div>
            </div>
            <div className={s.order_third_row_action}>
                {mainButton}
                {subButton}
            </div>
        </div>
    );
};

export default OrderHovered;