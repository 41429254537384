import React from 'react';
import {useSnackbar} from "notistack";
import phone from './../../assets/workspace/icons8-phone-400.svg'
import s from './CallButton.module.scss'
import {CrmAPI} from "../../../entities/api/CrmAPI";

interface p {
    clientId: string
    size: number,
    onClick?: () => void
}

const CallButton = (p: p) => {
    const {enqueueSnackbar} = useSnackbar()

    return (
        <img src={phone} className={s.wrapper} style={{height: p.size + 'px', width: p.size + 'px'}} onClick={() => {
            CrmAPI.Call(p.clientId).then(() => {
                enqueueSnackbar('Приймите звонок', {variant: 'success', autoHideDuration: 3000})
            }).catch(() => {
                enqueueSnackbar('Помилка дзвінву', {variant: 'error', autoHideDuration: 3000})
            })
            p.onClick ? p.onClick : null
        }}/>
    );
};

export default CallButton;