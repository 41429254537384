import React, {useState} from 'react';
import s from './OrderModal.module.scss'
import {useCurrency} from "../../../../entities";
import {Button} from "../../../../shared/ui";
import useOrderModal from "./OrderModalStore";
import useOrderManager from "../../../../features/OrderManager/OrderManagerStore";
import {useSnackbar} from "notistack";
import OrderProductTable from "../OrderProductTable/OrderProductTable";

const OrderModalPage2 = () => {
    const [openChooseProduct, setOpenChooseProduct] = useState(false)
    const productTable = useOrderModal(s => s.productTable)
    const addProduct = useOrderModal(s => s.addProduct)
    const isDirty = useOrderModal(s => s.isDirty)
    const updateOrder = useOrderModal(s => s.updateOrder)
    const incButtonHandler = useOrderModal(s => s.incButtonHandler)
    const decButtonHandler = useOrderModal(s => s.decButtonHandler)
    const removeButtonHandler = useOrderModal(s => s.removeButtonHandler)
    const sourceOrder = useOrderModal(s => s.sourceOrder)

    const {enqueueSnackbar} = useSnackbar()

    const replaceOrder = useOrderManager(s => s.replaceOrder)


    const fbts = useCurrency(s => s.fromBaseToSelected)
    const r = useCurrency(s => s.roundUp)

    return (
        <div className={s.page2}>

            <div className={s.table}
                 style={(sourceOrder != null && !sourceOrder.order.isPayed && !sourceOrder.order.isCollected) ? {} : {
                     pointerEvents: 'none',
                     opacity: '0.5'
                 }}>
                <OrderProductTable products={productTable} addProduct={(d) => {
                    addProduct(d, 1)
                }} removeButtonHandler={removeButtonHandler} incButtonHandler={incButtonHandler}
                                   decButtonHandler={decButtonHandler}/>
            </div>


            <div className={s.btns}>
                <Button disabled={isDirty.length === 0} onClick={() => {
                    updateOrder((o) => {
                        replaceOrder(o)
                        enqueueSnackbar('Заказ обновлен', {variant: 'success', autoHideDuration: 3000})
                    })
                }}>Зребегти</Button>
            </div>

        </div>
    );
};

export default OrderModalPage2;