export const DateConvertor = {
    GetDate: (date: string): string => {
        let d = new Date(date);
        return d.toLocaleDateString()
    },
    GetDateNoYear: (date: string): string => {
        let d = new Date(date);

        const month = d.getMonth() + 1; // Adding 1 because months are zero-based
        const day = d.getDate();

        return `${day}.${month}`;

    },

    GetHoursMinutes: (date: string): string => {
        let d = new Date(date);

        const hours = d.getHours();
        let minutes = d.getMinutes().toString();
        if (minutes.length === 1) minutes = "0" + minutes

        return `${hours}:${minutes}`;

    }
}