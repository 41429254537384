import {create} from "zustand";
import {devtools, persist} from "zustand/middleware";
import {immer} from "zustand/middleware/immer";
import {ProductCardPageResponse} from "../../../entities/DataTransferObjects/responses/ProductCardPageResponse";
import {NomenclatureManagerAPI} from "../../../entities/api/NomenclatureManagerAPI";
import {ProductCardAPI, ProductFullData} from "../../../entities";

interface p {
    urgency: 1 | 2 | 3
    setUrgency: (v: 1 | 2 | 3) => void

    mode: 1 | 2 | 3 | 4 | 5
    setMode: (v: 1 | 2 | 3 | 4 | 5) => void

    page: number
    setPage: (n: number) => void

    data: ProductCardPageResponse | null

    selectedRow: ProductFullData | null
    setSelectedRow: (v: ProductFullData | null) => void

    loadData: () => void

    sortProduct: (pordId: number, catId: number, os: () => void) => void
}

export const useNomenclatureManager = create<p>()(persist(devtools(immer((set, get) => ({
    sortProduct: (pordId, catId, os) => {
        ProductCardAPI.changeCategory(pordId, catId).then((r) => {
            if (get().data != null && get().data!.products.length > 0) {
                let ent = get().data!.products.find(n => n.product.id === pordId)
                if (ent != undefined) {
                    let index = get().data!.products.indexOf(ent)
                    set({selectedRow: get().data!.products[index + 1]})
                }
                set(state => {
                    state.data!.products = state.data!.products.filter(n => n.product.id != pordId)
                })
                os()
            }
        })
    },
    selectedRow: null,
    setSelectedRow: v => set({selectedRow: v}),
    urgency: 1,
    setUrgency: v => {
        set({urgency: v})
        get().loadData()
    },

    mode: 1,
    setMode: v => {
        set({mode: v})
        get().loadData()
    },

    page: 1,
    setPage: n => {
        set({page: n})
        get().loadData()
    },

    data: null,

    loadData: () => {
        let mode = get().mode
        if (mode === 2) {
            NomenclatureManagerAPI.unsorted(get().urgency, get().page).then(r => {
                set({data: r.data})
            })
        }
        if (mode === 1) {
            NomenclatureManagerAPI.lowfill(get().urgency, get().page).then(r => {
                set({data: r.data})
            })
        }
        if (mode === 3) {
            NomenclatureManagerAPI.nophoto(get().urgency, get().page).then(r => {
                set({data: r.data})
            })
        }
        if (mode === 5) {
            NomenclatureManagerAPI.nooptions(get().urgency, get().page).then(r => {
                set({data: r.data})
            })
        }
        if (mode === 4) {
            NomenclatureManagerAPI.nodesc(get().urgency, get().page).then(r => {
                set({data: r.data})
            })
        }

    }
}))), {
    name: "useNomenclatureManager",
    version: 1
}));