import React from 'react';
import {UserCRMData} from "../../../../../entities/models/CRM/UserCRMData";
import s from './Deals.module.scss'
import {useClientManager} from "../../ClientManagerStore";
import CallButton from "../../../../../shared/ui/CallButton/CallButton";

const DealsRow = (p: { client: UserCRMData }) => {
    const setOpen = useClientManager(s => s.setClientModal)
    const setSelected = useClientManager(s => s.setSelected)
    return (
        <div className={s.row} onDoubleClick={() => {
            setOpen(true)
            setSelected(p.client)
        }}>
            <CallButton clientId={p.client.user.id} size={55}/>
            <div className={s.info}>
                <div className={s.label}>Клієнт:</div>
                <div
                    className={s.name}>{p.client.user.lastName + " " + p.client.user.firstName + " " + p.client.user.patronymic}</div>
            </div>
        </div>
    );
};

export default DealsRow;