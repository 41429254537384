import React, {useEffect} from 'react';
import ColdClientsRow from "./ColdClientsRow";
import s from './ColdClients.module.scss'
import {useClientManager} from "../../ClientManagerStore";

const ColdClientsTable = () => {
    const data = useClientManager(n => n.coldClientsData)
    const loadColdClientData = useClientManager(n => n.loadColdClientData)

    useEffect(() => {
        loadColdClientData(1)
    }, [])
    return (
        <div className={s.table}>
            {data ? data.users.map((user) => <ColdClientsRow client={user}/>) : null}
        </div>
    );
};

export default ColdClientsTable;