import React, {useState} from 'react';
import ReactPaginate from "react-paginate";
import s from './Pagination.module.scss'

const Pagination = (p: { pageCount: number, pageChange: (page: number) => void }) => {

    const [selected, setSelected] = useState(1)

    const PaginationLabel = (n: number) =>
        <div className={selected === n ? s.labelSel : s.label}>
            {n}
        </div>

    const Space = <div className={s.label}>...</div>
    const Next = <div className={s.label}>{"=>"}</div>
    const Prev = <div className={s.label}>{"<="}</div>


    return (
        <ReactPaginate
            className={s.wrapper}
            breakLabel={Space}
            nextLabel={Next}
            onPageChange={(v) => {
                setSelected(v.selected + 1)
                p.pageChange(v.selected + 1)
            }}
            pageLabelBuilder={PaginationLabel}
            pageRangeDisplayed={5}
            pageCount={p.pageCount}
            previousLabel={Prev}
            renderOnZeroPageCount={null}
        />
    );
};

export default Pagination;