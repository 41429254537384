import React, {useState} from 'react';
import {Button, CustomModal, CustomTextarea} from "../../../../shared/ui";
import s from './CancelOrderModal.module.scss'
import {useSnackbar} from "notistack";
import {OrderWithProducts} from "../../../../entities/entities/Order/OrderWithProducts";
import {OrderApi} from "../../../../entities/api/OrderApi";

const CancelOrderModal = (p: { open: boolean, setOpen: (v: boolean) => void, orderId: number, onUpdate: (v: OrderWithProducts) => void }) => {
    const {enqueueSnackbar} = useSnackbar()

    const [text, setText] = useState('')

    const handleConfirm = () => {
        if (text.length < 5) {
            enqueueSnackbar('Детально заповніть причину', {variant: 'warning', autoHideDuration: 3000})
            return
        }

        OrderApi.Cancel(p.orderId, text).then(r => {
            enqueueSnackbar('Замовлення скасовано', {variant: 'success', autoHideDuration: 3000})
            p.onUpdate(r.data)
            setText('')
            p.setOpen(false)
        })

    }

    return (
        <CustomModal
            open={p.open}
            onClose={() => {
                p.setOpen(false)
            }}
        >
            <div className={s.wrapper}>
                <div className={s.label}>Причина скасування замовлення:</div>
                <div className={s.text}>
                    <CustomTextarea value={text} onChange={e => setText(e.target.value)}/>
                </div>
                <div className={s.buttons}>
                    <Button onClick={() => p.setOpen(false)}>Вийти</Button>
                    <Button onClick={handleConfirm}>Видалити замовлення</Button>
                </div>
            </div>
        </CustomModal>
    );
};

export default CancelOrderModal;