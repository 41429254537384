import React from 'react';
import s from './NomenclatureManagerPage.module.scss'
import {useNomenclatureManager} from "./NomenclatureManagerPageStore";
import {Button} from "@mui/material";
import ProductCatalogTableRow
    from "../../../widgets/workspace/ProductCatalog/ProductCatalogTable/ProductCatalogTableRow";
import Pagination from "../../../shared/ui/UserInterfaceKIT/Pagination/Pagination";
import {EditProductCardModal} from "../../../features";
import {TagTreeView} from "../../../widgets";
import {useSnackbar} from "notistack";
import ImageSearchComponent from "./ImageSearchComponent";

const NomenclatureManagerPage = () => {
    const st = useNomenclatureManager(s => s)
    const {enqueueSnackbar} = useSnackbar()


    return (
        <div className={s.wrapper}>
            <EditProductCardModal/>
            <div className={s.head}>
                <div className={s.mode}>
                    <Button variant={'contained'} color={st.mode === 1 ? 'secondary' : 'primary'}
                            onClick={() => st.setMode(1)}>Низкое заполнение</Button>
                    <Button variant={'contained'} color={st.mode === 2 ? 'secondary' : 'primary'}
                            onClick={() => st.setMode(2)}>Не отсортирован</Button>
                    <Button variant={'contained'} color={st.mode === 3 ? 'secondary' : 'primary'}
                            onClick={() => st.setMode(3)}>Нет фото</Button>
                    <Button variant={'contained'} color={st.mode === 4 ? 'secondary' : 'primary'}
                            onClick={() => st.setMode(4)}>Нет описания</Button>
                    <Button variant={'contained'} color={st.mode === 5 ? 'secondary' : 'primary'}
                            onClick={() => st.setMode(5)}>Нет опций</Button>
                </div>
                <div className={s.urgency}>
                    <Button variant={'contained'} onClick={() => st.setUrgency(1)}
                            color={st.urgency === 1 ? 'secondary' : 'primary'}>Склады</Button>
                    <Button variant={'contained'} onClick={() => st.setUrgency(2)}
                            color={st.urgency === 2 ? 'secondary' : 'primary'}>Постачи</Button>
                    <Button variant={'contained'} onClick={() => st.setUrgency(3)}
                            color={st.urgency === 3 ? 'secondary' : 'primary'}>Все товары</Button>
                </div>
            </div>
            <div className={s.body}>
                {st.mode === 2 ?
                    <div className={s.tree}>
                        <TagTreeView onNodeDoubleClick={(p) => {
                            if (st.selectedRow != null) st.sortProduct(st.selectedRow?.product.id, p.id, () => {
                                enqueueSnackbar('Товар перемещен', {variant: 'success', autoHideDuration: 3000})
                            })
                        }}/>
                    </div>
                    : <></>}

                {st.mode === 3 ? <ImageSearchComponent/>
                    : <></>}
                {st.mode != 3 ?
                    <div className={s.wrap}>
                        <div className={s.wrap}>
                            <div className={s.table}>
                                {st.data?.products.map((product, index) => <ProductCatalogTableRow key={index}
                                                                                                   product={product}
                                                                                                   onClick={() => {
                                                                                                       st.setSelectedRow(product)
                                                                                                   }}
                                                                                                   isSelected={st.selectedRow?.product.id === product.product.id}/>)}
                            </div>
                            <div className={s.pag}>
                                <Pagination pageCount={st.data ? st.data.pageCount : 0} pageChange={(n) => {
                                    st.setPage(n)
                                }}/>
                            </div>
                        </div>
                    </div>
                    : <></>}

            </div>
        </div>
    );
};

export default NomenclatureManagerPage;