import {create} from "zustand"
import {devtools, persist} from "zustand/middleware"
import {immer} from "zustand/middleware/immer"

interface p {
    isOpen: boolean
    setIsOpen: (v: boolean) => void
}

const useSetCategoryImageModal = create<p>()(persist(devtools(immer((set, get) => ({
    isOpen: false,
    setIsOpen: v => set({isOpen: v}),
}))), {
    name: "useSetCategoryImageModal",
    version: 1
}));

export default useSetCategoryImageModal;