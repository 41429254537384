import {AxiosResponse} from "axios";
import {$api} from "../../shared";
import {CRMPageResponse} from "../models/CRM/CRMPageResponse";
import {CRMLostCallsResponse} from "../models/CRM/CRMLostCallsResponse";
import {UserCRMInteraction} from "../entities/CRM/UserCRMInteraction";
import {AddInteractionRequest} from "../DataTransferObjects/requests/AddInteractionRequest";
import {CreateCRMTaskRequest} from "../DataTransferObjects/requests/CreateCRMTaskRequest";
import {AddTaskStepCRMRequest} from "../DataTransferObjects/requests/AddTaskStepCRMRequest";
import {UserCRMTaskWithSteps} from "../DataTransferObjects/responses/UserCRMTaskWithSteps";
import {UserCRMData} from "../models/CRM/UserCRMData";

export const CrmAPI = {
    Call(clientId: string): Promise<AxiosResponse<string>> {
        return (
            $api.post<string>(`/crm/call?ClientId=${clientId}`)
        )
    },
    LostCalls(shopId: string): Promise<AxiosResponse<CRMLostCallsResponse>> {
        return (
            $api.get<CRMLostCallsResponse>(`/crm/lost?shopId=${shopId}`)
        )
    },
    ColdClients(shopId: string, page: number, pageSize: number): Promise<AxiosResponse<CRMPageResponse>> {
        return (
            $api.get<CRMPageResponse>(`/crm/cold?shopId=${shopId}&page=${page}&pageSize=${pageSize}`)
        )
    },
    HotClients(shopId: string, page: number, pageSize: number): Promise<AxiosResponse<CRMPageResponse>> {
        return (
            $api.get<CRMPageResponse>(`/crm/hot?shopId=${shopId}&page=${page}&pageSize=${pageSize}`)
        )
    },
    Deals(shopId: string, page: number, pageSize: number): Promise<AxiosResponse<CRMPageResponse>> {
        return (
            $api.get<CRMPageResponse>(`/crm/deals?shopId=${shopId}&page=${page}&pageSize=${pageSize}`)
        )
    },
    AddInteraction(data: AddInteractionRequest): Promise<AxiosResponse<UserCRMInteraction>> {
        return (
            $api.post<UserCRMInteraction>(`/crm/addinteraction`, data)
        )
    },

    CreateTask(data: CreateCRMTaskRequest): Promise<AxiosResponse<UserCRMTaskWithSteps>> {
        return (
            $api.post<UserCRMTaskWithSteps>(`/crm/createtask`, data)
        )
    },
    AddTaskStep(data: AddTaskStepCRMRequest): Promise<AxiosResponse<UserCRMTaskWithSteps>> {
        return (
            $api.post<UserCRMTaskWithSteps>(`/crm/addstep`, data)
        )
    },

    DataById(clientId: string): Promise<AxiosResponse<UserCRMData>> {
        return (
            $api.get<UserCRMData>(`/crm/databyid?clientId=${clientId}`)
        )
    },
}