import {create} from "zustand";
import {devtools, persist} from "zustand/middleware";
import {immer} from "zustand/middleware/immer";
import {CRMPageResponse} from "../../../entities/models/CRM/CRMPageResponse";
import {CrmAPI} from "../../../entities/api/CrmAPI";
import {LocalStorage} from "../../../entities";
import {CRMLostCallsResponse} from "../../../entities/models/CRM/CRMLostCallsResponse";
import {UserCRMData} from "../../../entities/models/CRM/UserCRMData";

interface p {
    coldClientsData: CRMPageResponse | null
    loadColdClientData: (page: number) => void

    lostClientsData: CRMLostCallsResponse | null
    loadLostClientsData: () => void

    hotClientsData: CRMPageResponse | null
    loadHotClientData: (page: number) => void

    dealsData: CRMPageResponse | null
    loadDeals: (page: number) => void

    clientModal: boolean
    setClientModal: (v: boolean) => void

    selected: UserCRMData | null
    updateUserData: (v: UserCRMData) => void
    setSelected: (v: UserCRMData | null) => void

}

export const useClientManager = create<p>()(persist(devtools(immer((set, get) => ({
    dealsData: null,
    loadDeals: (page) => {
        CrmAPI.Deals(LocalStorage.shopId()!, page, 20).then((r) => {
            set({dealsData: r.data})
        })
    },
    updateUserData: (v) => {
        let sel = get().selected
        if (sel != null && sel.user.id === v.user.id) {
            set(state => {
                state.selected = v
            })
        }

        let ccd = get().coldClientsData
        if (ccd != null) {
            let cold = ccd.users.map(u => {
                if (u.user.id === v.user.id) return v
                return u
            })
            let data = {...ccd, users: cold}
            set({coldClientsData: data})
        }

        let hcd = get().hotClientsData
        if (hcd != null) {
            let hot = hcd.users.map(u => {
                if (u.user.id === v.user.id) return v
                return u
            })
            let data = {...hcd, users: hot}
            set({hotClientsData: data})
        }

        let deals = get().dealsData
        if (deals != null) {
            let d = deals.users.map(u => {
                if (u.user.id === v.user.id) return v
                return u
            })
            let data = {...deals, users: d}
            set({dealsData: data})
        }


    },
    selected: null,
    setSelected: v => set({selected: v}),
    hotClientsData: null,
    loadHotClientData: (page) => {
        CrmAPI.HotClients(LocalStorage.shopId()!, page, 20).then((r) => {
            set({hotClientsData: r.data})
        })
    },
    clientModal: false,
    setClientModal: v => set({clientModal: v}),
    lostClientsData: null,
    loadLostClientsData: () => {
        CrmAPI.LostCalls(LocalStorage.shopId()!).then((r) => {
            set({lostClientsData: r.data})
        })
    },
    coldClientsData: null,
    loadColdClientData: (page) => {
        CrmAPI.ColdClients(LocalStorage.shopId()!, page, 20).then((r) => {
            set({coldClientsData: r.data})
        })
    },
}))), {
    name: "useClientManager",
    version: 1
}));