import React, {ReactElement, useEffect} from 'react'
import {BikeShopPaths} from 'app/routes/paths'
import {useNavigate} from "react-router-dom"
import {useAuth} from "../../../entities";
import {useApp} from "../../../entities/globalStore/AppStore";

interface CheckAuthRouteProps {
    children: ReactElement
}

export const CheckAuthEmployee: React.FC<CheckAuthRouteProps> = ({children}) => {
    const navigate = useNavigate();
    const user = useAuth(s => s.user)
    const shop = useAuth(s => s.shop)
    const loginToShop = useAuth(s => s.loginToShop)

    const navTo = useApp(s => s.navTo)
    const setNavTo = useApp(s => s.setNavTo)

    useEffect(() => {
        if (navTo != null) {
            navigate(navTo)
            setNavTo(null)
        }
    }, [navTo])

    useEffect(() => {
        if (localStorage.getItem('accessToken') === null || user === undefined) {
            navigate(BikeShopPaths.COMMON.LOGIN)
        }
        if (user != undefined && user.shopId > 0) {
            if (shop === undefined) {
                loginToShop(user?.shopId!);
            }
        } else {
            navigate(BikeShopPaths.COMMON.LOGIN)
        }
    }, [user])

    useEffect(() => {
        if (shop != undefined) loginToShop(shop.id)
    }, [])

    return (
        <div>
            {children}
        </div>
    );
};