import React from 'react';
import ClientsBalanceManager from "../../../features/ClientsBalanceManager/ClientsBalanceManager";

const CrmPage = () => {
    return (

        <ClientsBalanceManager/>

    );
};

export default CrmPage;