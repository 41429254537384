import React from 'react';
import s from './../../ClientManager.module.scss'
import ColdClientsTable from "./ColdClientsTable";
import Pagination from "../../../../../shared/ui/UserInterfaceKIT/Pagination/Pagination";
import {useClientManager} from "../../ClientManagerStore";

const ColdClientsBody = () => {
    const data = useClientManager(n => n.coldClientsData)
    const loadColdClientData = useClientManager(n => n.loadColdClientData)
    return (
        <div className={s.coldclientbody}>
            <ColdClientsTable/>
            <Pagination pageCount={data ? data.pageCount : 0} pageChange={(page) => {
                loadColdClientData(page)
            }}/>
        </div>
    );
};

export default ColdClientsBody;