import {create} from "zustand"
import {devtools, persist} from "zustand/middleware"
import {immer} from "zustand/middleware/immer"
import {ProductCatalogkeyQuantity} from "../../../entities/models/ProductCatalogkeyQuantity";
import {NomenclatureManagerAPI} from "../../../entities/api/NomenclatureManagerAPI";
import {CreateStorageResponse} from "../../../entities/DataTransferObjects/responses/StorageResponse";
import {ProductImportDTO} from "../../../entities/DataTransferObjects/requests/ProductImportDTO";

interface p {
    selectState: { key: number, option: { name: string } }[]
    setSelectState: (option: { name: string }, column: number) => void

    data: CELL[][]
    setDataFromFile: (file: any[]) => void

    columnsArray: string[]

    processUpdate: () => ProductCatalogkeyQuantity[]
    processImport: () => ProductImportDTO[]
    importExecute: () => void
    infoUpd: () => void
    updateQuantity: () => void

    storage: CreateStorageResponse | null
    setStorage: (v: CreateStorageResponse | null) => void

    incomeCof: number
    setIncomeCof: (v: number) => void
    retailCof: number
    setRetailCof: (v: number) => void
}

interface CELL {
    value: string
    key: number,
}

const useImport = create<p>()(persist(devtools(immer((set, get) => ({
    incomeCof: 1,
    retailCof: 1,
    setIncomeCof: v => set({incomeCof: v}),
    setRetailCof: v => set({retailCof: v}),
    importExecute: () => {
        let data = get().processImport()
        NomenclatureManagerAPI.importProducts(data).then((r) => {
            console.log("response:", r.data)
        })
    },
    processImport: () => {
        let keyIndex = get().selectState.find(n => n.option.name === "CatalogKey")!.key + 1
        let NameIndex = get().selectState.find(n => n.option.name === "Name")!.key + 1
        let IncPriceIndex = get().selectState.find(n => n.option.name === "IncomePrice")!.key + 1
        let RetPriceIndex = get().selectState.find(n => n.option.name === "RetailPrice")!.key + 1

        let II = get().selectState.find(n => n.option.name === "Category")
        let ImportCategoryIndex = II != undefined ? II.key + 1 : 0

        let bk = get().selectState.find(n => n.option.name === "ManufBarcode")
        let BarcIndex = bk != undefined ? bk.key + 1 : 0

        let incCoef = get().incomeCof
        let retCoef = get().retailCof

        let data: ProductImportDTO[] = get().data.map(n => {
            let catKey = null
            let Name = null
            let IncPrc = null
            let RetPrc = null
            let Categ = null
            let Barcode = null
            n.forEach((cell) => {
                if (cell.key === keyIndex) catKey = cell.value.toString()
                if (cell.key === NameIndex) Name = cell.value.toString()
                if (cell.key === IncPriceIndex) IncPrc = cell.value
                if (cell.key === RetPriceIndex) RetPrc = cell.value
                if (ImportCategoryIndex != 0 && cell.key === ImportCategoryIndex) Categ = cell.value.toString()
                if (BarcIndex != 0 && cell.key === BarcIndex) Barcode = cell.value.toString()
            })

            if (catKey != null && Name != null && !isNaN(parseFloat(IncPrc != null ? IncPrc : "0")) && !isNaN(parseFloat(RetPrc != null ? RetPrc : "0")))
                return ({
                    catalogKey: catKey,
                    name: Name,
                    manufacturerBarcode: Barcode != null ? Barcode : undefined,
                    importCategory: Categ != null ? Categ : undefined,
                    incomePrice: parseFloat(IncPrc != null ? IncPrc : "0") * incCoef,
                    retailPrice: parseFloat(RetPrc != null ? RetPrc : "0") * retCoef
                } as ProductImportDTO)
        }).filter(n => n != undefined) as ProductImportDTO[]


        console.log(data)

        return (data)
    },
    updateQuantity: () => {
        let data = get().processUpdate()
        NomenclatureManagerAPI.updateQuantity(data, get().storage?.id!).then((r) => {
            console.log("response:", r.data)
        })
    },
    storage: null,
    setStorage: v => set({storage: v}),
    processUpdate: () => {
        let keyIndex = get().selectState.find(n => n.option.name === "CatalogKey")!.key + 1
        let QuantityIndex = get().selectState.find(n => n.option.name === "Quantity")!.key + 1

        let data: ProductCatalogkeyQuantity[] = get().data.map(n => {
            let catKey = null
            let quant = null
            n.forEach((cell) => {
                if (cell.key === keyIndex) catKey = cell.value.toString()
                if (cell.key === QuantityIndex) quant = cell.value
            })

            if (catKey != null && quant != null && !isNaN(parseFloat(quant)))
                return ({catalogKey: catKey, quantity: parseFloat(quant)})
        }).filter(n => n != undefined).filter(n => n!.quantity > 0) as ProductCatalogkeyQuantity[]

        console.log(data)

        return (data)
    },
    infoUpd: () => {
        let data = get().processUpdate()
        NomenclatureManagerAPI.updInfo(data, get().storage?.id!).then((r) => {
            console.log("response:", r.data)
        })
    },
    columnsArray: [],
    data: [],
    setDataFromFile: (file) => {
        let max = 0
        let data = file.map((row: any[]) => {
            if (max < row.length) max = row.length
            let roww: CELL[] = Object.keys(row).map((key) => {
                let kekwek: CELL = {
                    key: parseFloat(key),
                    value: row[parseFloat(key)],
                };
                return kekwek;
            });
            return roww;
        });
        set({columnsArray: new Array(max).fill(" ")})
        set({data: data})
    },
    selectState: [],
    setSelectState: (option, column) => {
        let exist = false
        let sel = get().selectState.map(n => {
            if (n.key === column) {
                exist = true
                n.option = option
                return n
            } else return n
        })
        if (!exist) sel.push({key: column, option: option})

        set({selectState: sel.filter(n => n.option != null)})
    }

}))), {
    name: "ImportStore",
    version: 1
}));

export default useImport;