import React from 'react';
import s from './CRMClientData.module.scss'
import {useCurrency, User} from "../../../../entities";
import CallButton from "../../../../shared/ui/CallButton/CallButton";

const CrmClientData = (p: { user?: User }) => {
    const r = useCurrency(r => r.roundUp)
    const f = useCurrency(r => r.fromBaseToSelected)

    if (p.user == null) return <div className={s.wrapper}>Користувач не знайден</div>

    return (
        <div className={s.wrapper}>
            <div className={s.fio}>{p.user.lastName + " " + p.user.firstName + " " + p.user.patronymic}</div>
            <div className={s.info}>
                <CallButton clientId={p.user.id} size={45}/>
                <div className={s.box}>
                    <div className={s.label}>Баланс:</div>
                    {r(p.user.balance * f.c) + f.s}
                </div>
                <div className={s.box}>
                    <div className={s.label}>Ліміт:</div>
                    {r(p.user.creditLimit * f.c) + f.s}
                </div>
                <div className={s.box}>
                    <div className={s.label}>Підтв.?:</div>
                    {p.user.emailConfirmed ? "ТАК" : "НІ"}
                </div>
                <div className={s.box} style={{fontSize: '14px'}}>
                    {p.user.id}
                </div>
            </div>
        </div>
    );
};

export default CrmClientData;