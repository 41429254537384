import React, {useState} from 'react';
import {RegistrationData, useAuth} from "../../../entities";
import {useNavigate} from "react-router-dom";
import {phoneMaskRemove} from "../../../shared/utils/phoneMaskRemove";
import {BikeShopPaths} from "../../../app/routes/paths";
import {
    Box,
    Button,
    Checkbox,
    Container,
    FormControl,
    FormControlLabel,
    IconButton,
    InputAdornment,
    InputLabel,
    OutlinedInput,
    Paper,
    TextField,
    Typography
} from "@mui/material";
import p from "../../../shared/assets/shop/images/header-photo_wide.jpg";
import {LoaderScreen} from "../../../shared/ui";
import InputMask from "react-input-mask";
import {AccountCircle, Visibility, VisibilityOff} from "@mui/icons-material";

const RegistrationPageMui = () => {
    const register = useAuth(s => s.register)
    const secretLogin = useAuth(s => s.secretLogin)
    const isLoading = useAuth(s => s.isLoading)
    const navigate = useNavigate()

    const [showPassword, setShowPassword] = React.useState(false);
    const [showPassword2, setShowPassword2] = React.useState(false);
    const [login, setLogin] = React.useState('');
    const [pass, setPass] = React.useState('');
    const [pass2, setPass2] = React.useState('');

    const handleClickShowPassword = () => setShowPassword((show) => !show);
    const handleClickShowPassword2 = () => setShowPassword2((show) => !show);

    const handleMouseDownPassword = (event: React.MouseEvent<HTMLButtonElement>) => {
        event.preventDefault();
    };
    const handleMouseDownPassword2 = (event: React.MouseEvent<HTMLButtonElement>) => {
        event.preventDefault();
    };

    const handleLoginClick = () => {
        sf1(null)
        sf2(null)
        let data: RegistrationData = {
            isAgree: true, // дополнительное поле?
            phone: phoneMaskRemove(login),
            password: pass
        }
        if (data.phone.length != 13) {
            sf1('Введіть номер повністю')
            return;
        }
        register(data,
            (data) => {
                navigate(BikeShopPaths.WORKSPACE.MAIN_PAGE)
            },
            (r: any) => {
                sf1('Помилка реєстрації')
                sf2('Помилка входу')
            })
    }

    const handleRegClick = () => {
        navigate(BikeShopPaths.COMMON.LOGIN)
    }
    const [f1, sf1] = useState<string | null>(null)
    const [f2, sf2] = useState<string | null>(null)

    return (
        <Container maxWidth={false} sx={{
            height: "100vh",
            display: 'flex',
            justifyContent: 'center',
            alignItems: "center",
            backgroundImage: `url(${p})`, backgroundSize: 'cover',
            backgroundPosition: 'center',
        }}>
            {isLoading ? <LoaderScreen variant={'ellipsis'}/> : <></>}
            <Box sx={{}}>
                <Paper elevation={5} sx={{display: 'flex', flexDirection: 'column', p: 4}}>
                    <Typography variant={'h5'} align={'center'}>Реєстрація</Typography>

                    <FormControl sx={{m: 1}} variant="outlined">
                        <InputMask
                            mask={"+38 (999) 999-99-99"}
                            value={login}
                            onChange={(e) => {
                                sf1(null)
                                setLogin(e.currentTarget.value)
                            }}
                            autoComplete={"off"}
                        >
                            <TextField
                                InputProps={{
                                    startAdornment: (
                                        <InputAdornment position="start">
                                            <AccountCircle/>
                                        </InputAdornment>
                                    ),
                                }}
                                id="outlined-adornment-login"
                                type={'text'}
                                label="Телефон"
                                helperText={f1}
                                error={f1 != null}
                            />
                        </InputMask>
                    </FormControl>

                    <FormControl sx={{m: 1}} variant="outlined">
                        <InputLabel htmlFor="outlined-adornment-password">Пароль</InputLabel>
                        <OutlinedInput
                            id="outlined-adornment-password"
                            type={showPassword ? 'text' : 'password'}
                            value={pass}
                            error={f2 != null}
                            onChange={(e) => {
                                sf2(null)
                                setPass(e.currentTarget.value)
                            }}
                            endAdornment={
                                <InputAdornment position="end">
                                    <IconButton
                                        aria-label="toggle password visibility"
                                        onClick={handleClickShowPassword}
                                        onMouseDown={handleMouseDownPassword}
                                        edge="end"
                                    >
                                        {showPassword ? <VisibilityOff/> : <Visibility/>}
                                    </IconButton>
                                </InputAdornment>
                            }
                            label="Пароль"
                        />
                    </FormControl>
                    <FormControl sx={{m: 1}} variant="outlined">
                        <InputLabel htmlFor="outlined-adornment-password2">Повторіть пароль</InputLabel>
                        <OutlinedInput
                            id="outlined-adornment-password2"
                            type={showPassword2 ? 'text' : 'password'}
                            value={pass2}
                            error={f2 != null}
                            onChange={(e) => {
                                sf2(null)
                                setPass2(e.currentTarget.value)
                            }}
                            endAdornment={
                                <InputAdornment position="end">
                                    <IconButton
                                        aria-label="toggle password visibility"
                                        onClick={handleClickShowPassword2}
                                        onMouseDown={handleMouseDownPassword2}
                                        edge="end"
                                    >
                                        {showPassword2 ? <VisibilityOff/> : <Visibility/>}
                                    </IconButton>
                                </InputAdornment>
                            }
                            label="Повторіть пароль"
                        />
                    </FormControl>
                    <FormControlLabel sx={{m: 1}} control={<Checkbox defaultChecked/>}
                                      label="З умовами користування згоден"/>
                    <Button sx={{mx: 2, my: 1}} variant={"contained"}
                            onClick={handleLoginClick}>Зареєструватись</Button>
                    <Button sx={{mx: 2, my: 1}} onClick={handleRegClick}>У мене вже є акаунт</Button>

                </Paper>
            </Box>
        </Container>
    );
};

export default RegistrationPageMui;