import {$api} from "../../shared";
import {AxiosResponse} from "axios";
import {ProductCardPageResponse} from "../DataTransferObjects/responses/ProductCardPageResponse";
import {ProductCatalogkeyQuantity} from "../models/ProductCatalogkeyQuantity";
import {ProductImportDTO} from "../DataTransferObjects/requests/ProductImportDTO";
import {ProductImportResponse} from "../DataTransferObjects/responses/ProductImportResponse";

export const NomenclatureManagerAPI = {
    unsorted(urgency: number, page: number): Promise<AxiosResponse<ProductCardPageResponse>> {
        return $api.get(`nomenclature/unsorted?Page=${page}&urgency=${urgency}`)
    },
    lowfill(urgency: number, page: number): Promise<AxiosResponse<ProductCardPageResponse>> {
        return $api.get(`nomenclature/lowfill?Page=${page}&urgency=${urgency}`)
    },
    nophoto(urgency: number, page: number): Promise<AxiosResponse<ProductCardPageResponse>> {
        return $api.get(`nomenclature/nophoto?Page=${page}&urgency=${urgency}`)
    },
    nooptions(urgency: number, page: number): Promise<AxiosResponse<ProductCardPageResponse>> {
        return $api.get(`nomenclature/nooptions?Page=${page}&urgency=${urgency}`)
    },
    nodesc(urgency: number, page: number): Promise<AxiosResponse<ProductCardPageResponse>> {
        return $api.get(`nomenclature/nodesc?Page=${page}&urgency=${urgency}`)
    },
    updInfo(data: ProductCatalogkeyQuantity[], storageId: number): Promise<AxiosResponse<ProductCardPageResponse>> {
        return $api.post(`nomenclature/infoupd?storageId=${storageId}`, data)
    },
    updateQuantity(data: ProductCatalogkeyQuantity[], storageId: number): Promise<AxiosResponse<ProductCardPageResponse>> {
        return $api.post(`nomenclature/quantupdate?storageId=${storageId}`, data)
    },
    importProducts(data: ProductImportDTO[]): Promise<AxiosResponse<ProductImportResponse>> {
        return $api.post(`nomenclature/importproducts`, data)
    },
}