import React, {useState} from 'react';
import {Button, CustomInput, CustomModal, CustomTextarea, DeleteButton} from "../../../../../shared/ui";
import s from './CrmCreateInteractionModal.module.scss'
import {useSnackbar} from "notistack";
import {CrmAPI} from "../../../../../entities/api/CrmAPI";
import {UserCRMInteraction} from "../../../../../entities/entities/CRM/UserCRMInteraction";
import {AddInteractionRequest} from "../../../../../entities/DataTransferObjects/requests/AddInteractionRequest";

const CrmCreateInteractionModal = (p: { open: boolean, setOpen: (v: boolean) => void, clientId: string, type: string, status: string, onAdd: (v: UserCRMInteraction) => void }) => {
    const [th, setTh] = useState("")
    const [info, setInfo] = useState("")
    const {enqueueSnackbar} = useSnackbar()

    let typeText = ""

    let statusText: { name: string, style: any } = {name: "", style: null}

    if (p.status === "Irrelevant") statusText = {name: "Низька", style: {background: "#7ee083"}}
    if (p.status === "Neutral") statusText = {name: "Прохідна", style: {background: "#e0d37e"}}
    if (p.status === "Important") statusText = {name: "Висока", style: {background: "#e07e7e"}}

    if (p.type === "ManualCall") typeText = "Телефонна розмова з клієнтом"
    if (p.type === "FaceToFace") typeText = "Очна розмова з клієнтом"
    if (p.type === "ManualChat") typeText = "Переписка з клієнтом"
    if (p.type === "ExtInfo") typeText = "Інформація про клієнта"

    const handleConfirm = () => {
        if (th.length < 3 || info.length < 5) {
            enqueueSnackbar('Зіповніть інформацію', {variant: 'warning', autoHideDuration: 3000})
            return
        }

        let data: AddInteractionRequest = {
            clientId: p.clientId,
            thematic: th,
            info: info,
            status: p.status,
            type: p.type
        }
        CrmAPI.AddInteraction(data).then(r => {
            setTh('')
            setInfo('')
            p.onAdd(r.data)
            p.setOpen(false)
            enqueueSnackbar('Взаємодія створена!', {variant: 'warning', autoHideDuration: 3000})
        })
    }
    return (
        <CustomModal
            open={p.open}
            onClose={() => {
                p.setOpen(false)
            }}
        >
            <div className={s.wrapper}>
                <div className={s.block1}>
                    <div className={s.buf}>
                        <div className={s.type}>
                            <div className={s.label}>Тип взіємодії з клієнтом:</div>
                            <div className={s.value}>{typeText}</div>
                        </div>
                        <div className={s.conf}>
                            <Button onClick={handleConfirm}>Зберегти</Button>
                        </div>
                    </div>
                    <div className={s.thematic}>
                        <CustomInput placeholder={'ТЕМА'} value={th} onChange={e => setTh(e.target.value)}/>
                        <DeleteButton size={40} onClick={() => {
                            setInfo("")
                            setTh("")
                            p.setOpen(false)
                        }}/>
                    </div>
                </div>
                <div className={s.block2} style={statusText.style}>
                    <div className={s.label}>Важливість:</div>
                    <div className={s.value}>{statusText.name}</div>
                </div>
                <div className={s.info}>
                    <CustomTextarea value={info} onChange={e => setInfo(e.target.value)}
                                    placeholder={"Детальна інформація"}/>
                </div>
            </div>
        </CustomModal>
    );
};

export default CrmCreateInteractionModal;