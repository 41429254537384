import React, {useEffect} from 'react';
import s from './HotClient.module.scss'
import {useClientManager} from "../../ClientManagerStore";
import HotClientRow from "./HotClientRow";

const HotClientTable = () => {
    const data = useClientManager(n => n.hotClientsData)
    const loadHotClientData = useClientManager(n => n.loadHotClientData)

    useEffect(() => {
        loadHotClientData(1)
    }, [])
    return (
        <div className={s.table}>
            {data ? data.users.map((user) => <HotClientRow client={user}/>) : null}
        </div>
    );
};

export default HotClientTable;