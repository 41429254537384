import React, {useEffect} from 'react';
import {useClientManager} from "../../ClientManagerStore";
import Pagination from "../../../../../shared/ui/UserInterfaceKIT/Pagination/Pagination";
import s from './Deals.module.scss'
import s2 from './../../ClientManager.module.scss'
import DealsRow from "./DealsRow";

const DealsBody = () => {
    const data = useClientManager(n => n.dealsData)
    const load = useClientManager(n => n.loadDeals)

    useEffect(() => {
        load(1)
    }, [])

    return (
        <div className={s2.deals}>
            <div className={s.table}>
                {data?.users.map(u => <DealsRow client={u}/>)}
            </div>
            <Pagination pageCount={data ? data.pageCount : 0} pageChange={(page) => {
                load(page)
            }}/>
        </div>
    );
};

export default DealsBody;