import {$api} from "../../shared";
import {AxiosResponse} from "axios";
import {Discount} from "../DataTransferObjects/Discount";
import {CalculateDiscountRequest} from "../models/CalculateDiscountRequest";

export const DiscountAPI = {
    getDiscountsByTarget(target: string, user: string): Promise<AxiosResponse<Discount[]>> {
        return (
            $api.get<Discount[]>(`/discount/getbytarget?target=${target}&user=${user}`)
        )
    },
    calculateDiscount(data: CalculateDiscountRequest[]): Promise<AxiosResponse<number[]>> {
        return (
            $api.post<number[]>(`/discount/calculate`, data)
        )
    },
}