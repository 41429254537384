import React, {useEffect} from 'react'
import s from './OrderManager.module.scss'
import ColumnOrder from "./ColumnOrder";
import OrderModal from "../../widgets/workspace/Orders/OrderModal/OrderModal";
import useOrderManager from "./OrderManagerStore";
import useCreateOrderModal from "../../widgets/workspace/Orders/CreateOrderModal/CreateOrderModalStore";
import CreateOrderModal from "../../widgets/workspace/Orders/CreateOrderModal/CreateOrderModal";
import {PayModal} from "../PayModal/PayModal";
import CRMClientModal from "../../widgets/workspace/CRM/CRMClientModal/CRMClientModal";
import CancelOrderModal from "../../widgets/workspace/Orders/CancelOrderModal/CancelOrderModal";
import ConfirmOrderIssueModal from "../../widgets/workspace/Orders/ConfirmOrderIssueModal/ConfirmOrderIssueModal";

const OrderManager = () => {

    const data = useOrderManager(s => s.orders)
    const getOrders = useOrderManager(s => s.getOrders)
    const payModal = useOrderManager(s => s.payModal)
    const setPayModal = useOrderManager(s => s.setPayModal)
    const currentOrder = useOrderManager(s => s.currentOrder)
    const pay = useOrderManager(s => s.pay)
    const open = useOrderManager(s => s.crmModal)
    const setOp = useOrderManager(s => s.setCrmModal)
    const cancelModal = useOrderManager(s => s.cancelModal)
    const setCancelModal = useOrderManager(s => s.setCancelModal)
    const replaceOrder = useOrderManager(s => s.replaceOrder)
    const updateClientCRMData = useOrderManager(s => s.updateClientCRMData)
    const confirmIssueModal = useOrderManager(s => s.confirmIssueModal)
    const setConfirmIssueModal = useOrderManager(s => s.setConfirmIssueModal)

    const setOpen = useCreateOrderModal(s => s.setOpen)

    const w8Manager = data.filter(n => n.order.orderStatus === "WaitingForCollection" || n.order.orderStatus === "Created" || n.order.orderStatus === "WaitingForShipping")
    const w8Client = data.filter(n => n.order.orderStatus === "WaitingForPayment")
    const w8Process = data.filter(n => n.order.orderStatus === "WaitingLogistic")
    const finishing = data.filter(n => n.order.orderStatus === "ReadyInShop" || n.order.orderStatus === "Shipped")


    useEffect(() => {
        getOrders()
    }, [])
    return (
        <div className={s.wrapper} onContextMenu={e => e.preventDefault()}>
            {
                currentOrder != null ? <>
                    <ConfirmOrderIssueModal open={confirmIssueModal} setOpen={setConfirmIssueModal}/>
                    <PayModal open={payModal} setOpen={setPayModal} user={currentOrder.client.user}
                              summ={currentOrder ? currentOrder.order.totalPrice : 0}
                              result={(pd) => {
                                  pay(currentOrder!.order.id, pd)
                              }}/>
                    <CRMClientModal open={open} setOpen={setOp} data={currentOrder!.client}
                                    updateData={updateClientCRMData}/>
                    <CancelOrderModal open={cancelModal} setOpen={setCancelModal} orderId={currentOrder.order.id}
                                      onUpdate={replaceOrder}/>
                </> : null
            }

            <OrderModal/>

            <CreateOrderModal/>
            <div className={s.main_block}>
                <div className={s.column}>
                    <div className={s.column_name}>Очікує менеджера</div>
                    <div className={s.column_add_button} onClick={() => {
                        setOpen(true)
                    }}>+
                    </div>
                    <div className={s.column_orders}>
                        {w8Manager.map((n, ind) => {
                            return (
                                <ColumnOrder order={n} key={ind}/>
                            )
                        })}
                    </div>
                </div>
                <div className={s.column}>
                    <div className={s.column_name}>Очікує клієнта</div>
                    <div className={s.column_orders}>
                        {w8Client.map((n, ind) => {
                            return (
                                <ColumnOrder order={n} key={ind}/>
                            )
                        })}
                    </div>
                </div>
                <div className={s.column}>
                    <div className={s.column_name}>У процессі обробки</div>
                    <div className={s.column_orders}>
                        {w8Process.map((n, ind) => {
                            return (
                                <ColumnOrder order={n} key={ind}/>
                            )
                        })}
                    </div>
                </div>
                <div className={s.column}>
                    <div className={s.column_name}>Завершення</div>
                    <div className={s.column_orders}>
                        {finishing.map((n, ind) => {
                            return (
                                <ColumnOrder order={n} key={ind}/>
                            )
                        })}
                    </div>
                </div>
            </div>
        </div>
    );
};

export default OrderManager;