import React, {useState} from 'react';
import s from "./OrderProductTable.module.scss";
import {AsyncSelectSearchProduct, DeleteButton} from "../../../../shared/ui";
import {ChooseProductModal} from "../../ProductCatalog/ChooseProductModal/ChooseProductModal";
import {Product, useCurrency} from "../../../../entities";
import {OrderProduct} from "../../../../entities/entities/Order/OrderProduct";

interface p {
    incButtonHandler: (id: number) => void
    decButtonHandler: (id: number) => void
    removeButtonHandler: (id: number) => void
    addProduct: (v: Product) => void
    products: { p: Product, q: number }[] | OrderProduct[]
}


const OrderProductTable = (p: p) => {
    const fbts = useCurrency(s => s.fromBaseToSelected)
    const r = useCurrency(s => s.roundUp)

    let prod: { id: number, name: string, quantity: number, quanUnit: string, price: number }[] = []

    function isProductArray(input: any): input is { p: Product, q: number }[] {
        return Array.isArray(input) && input.every(item => 'p' in item && 'q' in item);
    }


    if (!isProductArray(p.products)) {
        prod = p.products.map(n => {
            return ({id: n.productId, name: n.name, price: n.price, quantity: n.quantity, quanUnit: n.quantityUnitName})
        })
    } else {
        prod = p.products.map(n => {
            return ({id: n.p.id, name: n.p.name, price: n.p.retailPrice, quantity: n.q, quanUnit: n.p.quantityUnitName})
        })
    }

    const [openChooseProduct, setOpenChooseProduct] = useState(false)

    return (
        <div className={s.products}>
            <ChooseProductModal open={openChooseProduct} setOpen={setOpenChooseProduct}
                                addData={(pr: Product) => {
                                    p.addProduct(pr)
                                    setOpenChooseProduct(false)
                                }}/>
            <div className={s.select}>
                <div style={{width: "100%"}}><AsyncSelectSearchProduct onSelect={p.addProduct}/>
                </div>
                <div className={s.s_b} onClick={() => {
                    setOpenChooseProduct(true)
                }}>+
                </div>
            </div>
            <div className={s.table}>
                {prod.map((n, ind) =>
                    <div className={s.table_row} key={ind}>
                        <div>{n.name}</div>
                        <div className={s.table_row_right}>
                            <div className={s.row_quant}>
                                <div className={s.row_quant_butt} onClick={() => {
                                    p.decButtonHandler(n.id)
                                }}>-
                                </div>
                                <div>{n.quantity + n.quanUnit}</div>
                                <div className={s.row_quant_butt} onClick={() => {
                                    p.incButtonHandler(n.id)
                                }}>+
                                </div>
                                <DeleteButton className={s.row_quant_butt} size={15}
                                              onClick={() => {
                                                  p.removeButtonHandler(n.id)
                                              }}/>
                            </div>
                            <div className={s.table_row_right_data}>
                                {r(n.price * n.quantity * fbts.c) + fbts.s}
                            </div>
                        </div>

                    </div>
                )}
            </div>
        </div>

    );
};

export default OrderProductTable;