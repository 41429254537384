import React, {useCallback} from 'react';
import {useDropzone} from 'react-dropzone';
import {read, utils, WorkSheet} from 'xlsx';

interface ExcelReaderProps {
    onDataRead: (data: any) => void;
}

const DropZone: React.FC<ExcelReaderProps> = ({onDataRead}) => {
    const onDrop = useCallback(
        (acceptedFiles: File[]) => {
            const file = acceptedFiles[0];
            const reader = new FileReader();

            reader.onload = (e) => {
                if (e.target) {
                    const data = e.target.result;
                    const workbook = read(data, {type: 'binary'});
                    const sheetName = workbook.SheetNames[0];
                    const sheet: WorkSheet = workbook.Sheets[sheetName];
                    const jsonData = utils.sheet_to_json(sheet, {header: 1});
                    onDataRead(jsonData.slice(1))
                }
            };

            reader.readAsArrayBuffer(file);
        },
        [onDataRead]
    );

    const {getRootProps, getInputProps, isDragActive} = useDropzone({onDrop});

    return (
        <div {...getRootProps()} style={dropzoneStyle}>
            <input {...getInputProps()} />
            {isDragActive ? (
                <p>Drop the Excel file here...</p>
            ) : (
                <p>Drag 'n' drop an Excel file here, or click to select one</p>
            )}
        </div>
    );
};

const dropzoneStyle: React.CSSProperties = {
    width: '300px',
    height: '100px',
    border: '2px dashed #ddd',
    borderRadius: '4px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    cursor: 'pointer',
};

export default DropZone;