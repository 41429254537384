import {BikeShopPaths} from './paths'
import {createBrowserRouter} from 'react-router-dom'
import {
    AdminControl,
    Cashbox,
    InventarizationPage,
    MainPage,
    ProductCatalog,
    Service,
    StorageProductsTransfer,
    StuffProfile,
    SupplyInvoice,
    WorkCatalog
} from '../../pages'
import {OnlyWithoutAuthRoute, WorkspaceHeaderProvider} from '../../entities'
import {CheckAuthEmployee} from "../providers/RouteProviders/CheckAuthEmployee"
import CRMPage from "../../pages/workspace/CRM/CRMPage";
import OrderManager from "../../features/OrderManager/OrderManager";
import OutcomeActPage from "../../pages/workspace/ProductsCount/OutcomeActPage/OutcomeActPage";
import SchedulePage from "../../pages/workspace/SchedulePage/SchedulePage";
import CardCatalog from "../../pages/workspace/CardCatalog/CardCatalog";
import HTMLParser from "../../pages/workspace/HTMLParser/HTMLParser";
import ProductCardManager from "../../pages/workspace/ProductCardManager/ProductCardManager";
import ClientManager from "../../pages/workspace/ClientManager/ClientManager";
import SupplyInvoiceMUI from "../../pages/restyle/SupplyInvoice/SupplyInvoiceMUI";
import LoginPageMui from "../../pages/auth/LoginPage/LoginPageMUI";
import RegistrationPageMui from "../../pages/auth/RegistrationPage/RegistrationPageMUI";
import NomenclatureManagerPage from "../../pages/workspace/NomenclatureManagerPage/NomenclatureManagerPage";
import DrivePage from "../../pages/workspace/DrivePage/DrivePage";
import Import from "../../pages/workspace/Import/Import";

// @ts-ignore
export const routes = createBrowserRouter([

    ////                    ////
    ////    Авторизация     ////
    ////                    ////

    {
        path: BikeShopPaths.COMMON.LOGIN,
        element: <OnlyWithoutAuthRoute>
            <LoginPageMui/>
        </OnlyWithoutAuthRoute>
    },
    {
        path: BikeShopPaths.COMMON.REGISTRATION,
        element: <OnlyWithoutAuthRoute>
            <RegistrationPageMui/>
        </OnlyWithoutAuthRoute>
    },

    ////                                        ////
    ////    Страницы только для залогиненых     ////
    ////                                        ////

    {
        path: BikeShopPaths.WORKSPACE.MAIN_PAGE,
        element: <CheckAuthEmployee>
            <WorkspaceHeaderProvider>
                <MainPage/>
            </WorkspaceHeaderProvider>
        </CheckAuthEmployee>
    },

    {
        path: BikeShopPaths.WORKSPACE.IMPORT,
        element: <CheckAuthEmployee>
            <WorkspaceHeaderProvider>
                <Import/>
            </WorkspaceHeaderProvider>
        </CheckAuthEmployee>
    },

    {
        path: '/',
        element: <CheckAuthEmployee>
            <WorkspaceHeaderProvider>
                <MainPage/>
            </WorkspaceHeaderProvider>
        </CheckAuthEmployee>
    },
    {
        path: BikeShopPaths.WORKSPACE.CASHBOX,
        element: <CheckAuthEmployee>
            <WorkspaceHeaderProvider>
                <Cashbox/>
            </WorkspaceHeaderProvider>
        </CheckAuthEmployee>
    },
    {
        path: BikeShopPaths.WORKSPACE.SERVICE,
        element: <CheckAuthEmployee>
            <WorkspaceHeaderProvider>
                <Service/>
            </WorkspaceHeaderProvider>
        </CheckAuthEmployee>
    },
    {
        path: BikeShopPaths.WORKSPACE.PRODUCT_CATALOG,
        element: <CheckAuthEmployee>
            <WorkspaceHeaderProvider>
                <ProductCatalog/>
            </WorkspaceHeaderProvider>
        </CheckAuthEmployee>
    },
    {
        path: BikeShopPaths.WORKSPACE.WORK_CATALOG,
        element: <CheckAuthEmployee>
            <WorkspaceHeaderProvider>
                <WorkCatalog/>
            </WorkspaceHeaderProvider>
        </CheckAuthEmployee>
    },
    {
        path: BikeShopPaths.WORKSPACE.ARRIVAL_OF_PRODUCTS,
        element: <CheckAuthEmployee>
            <WorkspaceHeaderProvider>
                <SupplyInvoice/>
            </WorkspaceHeaderProvider>
        </CheckAuthEmployee>
    },
    {
        path: BikeShopPaths.WORKSPACE.INVENTARIZATION,
        element: <CheckAuthEmployee>

            <InventarizationPage/>

        </CheckAuthEmployee>
    },
    {
        path: BikeShopPaths.WORKSPACE.STORAGE_PRODUCTS_TRANSFER,
        element: <CheckAuthEmployee>
            <WorkspaceHeaderProvider>
                <StorageProductsTransfer/>
            </WorkspaceHeaderProvider>
        </CheckAuthEmployee>
    },
    {
        path: BikeShopPaths.WORKSPACE.PROFILE,
        element: <CheckAuthEmployee>
            <WorkspaceHeaderProvider>
                <StuffProfile/>
            </WorkspaceHeaderProvider>
        </CheckAuthEmployee>
    },
    {
        path: BikeShopPaths.WORKSPACE.ADMIN,
        element: <CheckAuthEmployee>
            <WorkspaceHeaderProvider>
                <AdminControl/>
            </WorkspaceHeaderProvider>
        </CheckAuthEmployee>
    },
    {
        path: BikeShopPaths.WORKSPACE.CRM,
        element: <CheckAuthEmployee>
            <WorkspaceHeaderProvider>
                <CRMPage/>
            </WorkspaceHeaderProvider>
        </CheckAuthEmployee>
    },
    {
        path: BikeShopPaths.WORKSPACE.ORDERS,
        element: <CheckAuthEmployee>
            <WorkspaceHeaderProvider>
                <OrderManager/>
            </WorkspaceHeaderProvider>
        </CheckAuthEmployee>
    },

    {
        path: BikeShopPaths.WORKSPACE.OUTCOME_ACT,
        element: <CheckAuthEmployee>
            <WorkspaceHeaderProvider>
                <OutcomeActPage/>
            </WorkspaceHeaderProvider>
        </CheckAuthEmployee>
    },
    {
        path: BikeShopPaths.WORKSPACE.SCHEDULE,
        element: <CheckAuthEmployee>
            <WorkspaceHeaderProvider>
                <SchedulePage/>
            </WorkspaceHeaderProvider>
        </CheckAuthEmployee>
    },
    {
        path: BikeShopPaths.WORKSPACE.CARD_CATALOG,
        element: <CheckAuthEmployee>
            <WorkspaceHeaderProvider>
                <CardCatalog/>
            </WorkspaceHeaderProvider>
        </CheckAuthEmployee>
    },
    {
        path: BikeShopPaths.WORKSPACE.PRODUCT_MANAGER,
        element: <CheckAuthEmployee>
            <WorkspaceHeaderProvider>
                <ProductCardManager/>
            </WorkspaceHeaderProvider>
        </CheckAuthEmployee>
    },
    {
        path: BikeShopPaths.WORKSPACE.NOMENCLATURE,
        element: <CheckAuthEmployee>
            <WorkspaceHeaderProvider>
                <NomenclatureManagerPage/>
            </WorkspaceHeaderProvider>
        </CheckAuthEmployee>
    },
    {
        path: BikeShopPaths.WORKSPACE.HTML_PARSER,
        element: <CheckAuthEmployee>
            <WorkspaceHeaderProvider>
                <HTMLParser/>
            </WorkspaceHeaderProvider>
        </CheckAuthEmployee>
    },
    {
        path: BikeShopPaths.WORKSPACE.CLIENT_MANAGER,
        element: <CheckAuthEmployee>
            <WorkspaceHeaderProvider>
                <ClientManager/>
            </WorkspaceHeaderProvider>
        </CheckAuthEmployee>
    },
    {
        path: BikeShopPaths.WORKSPACE.SUPPLY_INVOICE,
        element: <CheckAuthEmployee>
            <WorkspaceHeaderProvider>
                <SupplyInvoiceMUI/>
            </WorkspaceHeaderProvider>
        </CheckAuthEmployee>
    },

    {
        path: BikeShopPaths.WORKSPACE.DRIVE,
        element: <CheckAuthEmployee>
            <WorkspaceHeaderProvider>
                <DrivePage/>
            </WorkspaceHeaderProvider>
        </CheckAuthEmployee>
    },

    // {
    //     path: BikeShopPaths.WORKSPACE.SHOP_CHECK,
    //     element: <CheckForShop/>
    // },
    // {
    //     path: BikeShopPaths.WORKSPACE.WORK_CHECK,
    //     element: <CheckForServiceWork/>
    // },
    // {
    //     path: BikeShopPaths.WORKSPACE.WORK_ACT,
    //     element: <ActServiceWork/>
    // },
    // {
    //     path: BikeShopPaths.WORKSPACE.GET_STUFF_TO_SERVICE_ACT,
    //     element: <ServiceIncomeInvoice/>
    // },
    // {
    //     path: BikeShopPaths.WORKSPACE.GET_STUFF_FROM_SERVICE_ACT,
    //     element: <ActGetStuffFromService/>
    // },
])