import {$api} from "../../shared";
import {OrderWithProducts} from "../entities/Order/OrderWithProducts";
import {AxiosResponse} from "axios/index";
import {CreateOrder} from "../models/Order/CreateOrder";
import {PayOrderRequest} from "../models/Order/PayOrderRequest";
import {UpdateOrderRequest} from "../models/Order/UpdateOrderRequest";

export const OrderApi = {
    //returns all 0 and shopId orders wich not closed
    //if 0, return all orders wich not closed
    GetByShop(shopId: string): Promise<AxiosResponse<OrderWithProducts[]>> {
        return (
            $api.get<OrderWithProducts[]>(`/order/getbyshop?ShopId=${shopId}`)
        )
    },

    Create(dto: CreateOrder): Promise<AxiosResponse<OrderWithProducts>> {
        return (
            $api.post<OrderWithProducts>(`/order/internalcreate`, dto)
        )
    },

    Confirm(userId: string, orderId: number): Promise<AxiosResponse<OrderWithProducts>> {
        return (
            $api.post<OrderWithProducts>(`/order/confirm?UserId=${userId}&OrderId=${orderId}`)
        )
    },
    Collected(userId: string, orderId: number): Promise<AxiosResponse<OrderWithProducts>> {
        return (
            $api.post<OrderWithProducts>(`/order/collected?UserId=${userId}&OrderId=${orderId}`)
        )
    },
    Shipped(userId: string, orderId: number): Promise<AxiosResponse<OrderWithProducts>> {
        return (
            $api.post<OrderWithProducts>(`/order/shipped?UserId=${userId}&OrderId=${orderId}`)
        )
    },
    Delivered(userId: string, orderId: number): Promise<AxiosResponse<OrderWithProducts>> {
        return (
            $api.post<OrderWithProducts>(`/order/delivered?UserId=${userId}&OrderId=${orderId}`)
        )
    },
    Pay(data: PayOrderRequest): Promise<AxiosResponse<OrderWithProducts>> {
        return (
            $api.post<OrderWithProducts>(`/order/pay`, data)
        )
    },
    Update(data: UpdateOrderRequest): Promise<AxiosResponse<OrderWithProducts>> {
        return (
            $api.post<OrderWithProducts>(`/order/fullupd`, data)
        )
    },
    Cancel(orderId: number, reason: string): Promise<AxiosResponse<OrderWithProducts>> {
        return (
            $api.post<OrderWithProducts>(`/order/cancel?OrderId=${orderId}&Reson=${reason}`)
        )
    },
    ToLogistic(orderId: number): Promise<AxiosResponse<OrderWithProducts>> {
        return (
            $api.post<OrderWithProducts>(`/order/tologistic?OrderId=${orderId}`)
        )
    },
    Issue(orderId: number): Promise<AxiosResponse<OrderWithProducts>> {
        return (
            $api.post<OrderWithProducts>(`/order/issue?OrderId=${orderId}`)
        )
    },
    ToCollection(orderId: number): Promise<AxiosResponse<OrderWithProducts>> {
        return (
            $api.post<OrderWithProducts>(`/order/tocollection?OrderId=${orderId}`)
        )
    },
}