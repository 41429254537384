import {create} from "zustand"
import {devtools, persist} from "zustand/middleware"
import {immer} from "zustand/middleware/immer"
import {OrderWithProducts} from "../../entities/entities/Order/OrderWithProducts";
import {OrderApi} from "../../entities/api/OrderApi";
import {LocalStorage, PaymentData} from "../../entities";
import {PayOrderRequest} from "../../entities/models/Order/PayOrderRequest";
import {UserCRMData} from "../../entities/models/CRM/UserCRMData";

interface p {
    orders: OrderWithProducts[]
    getOrders: () => void
    addOrder: (v: OrderWithProducts) => void
    confirm: (id: number) => void
    getStatusString: (status: string) => { s: string, style: {} }
    getDeliveryString: (status: string) => string
    collected: (id: number) => void
    shipped: (id: number) => void
    delivered: (id: number) => void
    pay: (id: number, pd: PaymentData) => void
    toLogistic: (id: number) => void
    issue: (id: number) => void
    toCollection: (id: number) => void
    replaceOrder: (o: OrderWithProducts) => void

    currentOrder: OrderWithProducts | null
    setCurrentOrder: (v: OrderWithProducts) => void

    payModal: boolean
    setPayModal: (v: boolean) => void

    confirmIssueModal: boolean,
    setConfirmIssueModal: (v: boolean) => void

    crmModal: boolean
    setCrmModal: (v: boolean) => void

    cancelModal: boolean
    setCancelModal: (v: boolean) => void

    updateClientCRMData: (v: UserCRMData) => void
}


const useOrderManager = create<p>()(persist(devtools(immer((set, get) => ({
    toCollection: (v) => {
        OrderApi.ToCollection(v).then(r => {
            get().replaceOrder(r.data)
        })
    },
    issue: (v) => {
        OrderApi.Issue(v).then(r => {
            get().replaceOrder(r.data)
        })
    },
    confirmIssueModal: false,
    setConfirmIssueModal: v => set({confirmIssueModal: v}),
    toLogistic: (v) => {
        OrderApi.ToLogistic(v).then(r => {
            get().replaceOrder(r.data)
        })
    },
    updateClientCRMData: (v) => {
        let curOr = get().currentOrder
        if (curOr?.order.clientId === v.user.id) set({currentOrder: {...curOr, client: v}})

        let data = get().orders.map(n => {
            if (n.order.clientId === v.user.id) return {...n, client: v}
            return n
        })
        set({orders: data})
    },
    cancelModal: false,
    setCancelModal: v => set({cancelModal: v}),
    crmModal: false,
    setCrmModal: v => set({crmModal: v}),
    replaceOrder: (o) => {
        let data = get().orders.map(n => {
            if (n.order.id === o.order.id) return o
            return n
        })
        if (get().currentOrder?.order.id === o.order.id) set({currentOrder: o})
        set({orders: data})
    },
    payModal: false,
    setPayModal: v => set({payModal: v}),
    pay: (id, pd) => {

        let data: PayOrderRequest = {
            payment: pd,
            orderId: id,
            userId: LocalStorage.userId()!
        }
        OrderApi.Pay(data).then(r => {
            get().replaceOrder(r.data)
        })
    },
    delivered: (id) => {
        OrderApi.Delivered(LocalStorage.userId()!, id).then(r => {
            get().replaceOrder(r.data)
        })
    },
    shipped: (id) => {
        OrderApi.Shipped(LocalStorage.userId()!, id).then(r => {
            get().replaceOrder(r.data)
        })
    },
    setCurrentOrder: (v) => set({currentOrder: v}),
    currentOrder: null,
    collected: (id) => {
        OrderApi.Collected(LocalStorage.userId()!, id).then(r => {
            get().replaceOrder(r.data)
        })
    },
    getDeliveryString: (status) => {
        switch (status) {
            case "DeliveryNP":
                return "Доставка новою поштою"
            case "ShopPickUp":
                return "Самовивіз"
            case "DeliveryBLC":
                return "Доставка самостійно"
            default:
                return "Помилка статуса доставки"
        }
    },
    getStatusString: (status) => {
        switch (status) {
            case "Created":
                return {s: "Очікує підтвердження", style: {}}
            case "WaitingForPayment":
                return {s: "Очікує оплату", style: {}}
            case "WaitingForCollection":
                return {s: "На збірці", style: {backgroundColor: "#ffe756", color: "#3f3f3f"}}
            case "WaitingLogistic":
                return {s: "Очікує логістики", style: {backgroundColor: "#ffe756", color: "#3f3f3f"}}
            case "ReadyInShop":
                return {s: "Готов в магазині", style: {backgroundColor: "#84e379"}}
            case "WaitingForShipping":
                return {s: "Очікує відправлення", style: {backgroundColor: "#ffe756", color: "#3f3f3f"}}
            case "Shipped":
                return {s: "Відпарвлено", style: {backgroundColor: "#84e379"}}
            case "Finished":
                return {s: "Завершено", style: {backgroundColor: "#84e379"}}
            case "Canceled":
                return {s: "Відменено", style: {}}
            default:
                return {s: "Ошибка статуса", style: {}}
        }
    },
    confirm: (id) => {
        OrderApi.Confirm(LocalStorage.userId()!, id).then(r => {
            get().replaceOrder(r.data)
        })
    },
    addOrder: (v) => set(state => {
        state.orders.push(v)
    }),
    orders: [],
    getOrders: () => {
        OrderApi.GetByShop("0").then(n => {
            console.log("Заказы", n.data)
            set({orders: n.data})
        })
    }
}))), {
    name: "orderManager",
    version: 1
}));

export default useOrderManager;