import React, {useEffect, useState} from 'react';
import s from './ProductHistoryModal.module.scss'
import {CustomModal} from "../../../shared/ui";
import {ProductHistoryItem} from "../../../entities/models/ProductHistoryItem";
import {EntitiesAPI} from "../../../entities";

const ProductHistoryModal = (p: { open: boolean, setOpen: (v: boolean) => void, pId?: number }) => {
    const [list, setList] = useState<ProductHistoryItem[]>([])

    useEffect(() => {
        if (p.open && p.pId != undefined) {
            EntitiesAPI.Storage.getProductHistory(p.pId).then(r => setList(r.data))
        }
    }, [p.open, p.pId])
    return (
        <CustomModal
            open={p.open}
            onClose={() => {
                p.setOpen(false)
            }}
        >
            <div className={s.wrapper}>
                {list.map(i =>
                    <div className={s.item}>
                        <div className={s.data}>
                            <div className={s.title}>Количество:</div>
                            <div className={s.value}>{i.quantity}</div>
                        </div>
                        <div className={s.data}>
                            <div className={s.title}>Тип перемещения:</div>
                            <div className={s.value}>{i.moveDirection}</div>
                        </div>
                        <div className={s.data}>
                            <div className={s.title}>Id склада:</div>
                            <div className={s.value}>{i.storageId}</div>
                        </div>
                        <div className={s.data}>
                            <div className={s.title}>Дата:</div>
                            <div className={s.value}>{i.createdAt}</div>
                        </div>
                        <div className={s.data}>
                            <div className={s.title}>Источник:</div>
                            <div className={s.value}>{i.productMoveSource}</div>
                        </div>
                        <div className={s.data}>
                            <div className={s.title}>Id источника:</div>
                            <div className={s.value}>{i.sourceId}</div>
                        </div>

                    </div>)}
            </div>
        </CustomModal>

    );
};

export default ProductHistoryModal;