import React, {useState} from 'react';
import {Button, CustomInput, CustomModal, CustomTextarea} from "../../../../shared/ui";
import s from './CRMClientTasks.module.scss';
import Select from "react-select";
import {AsyncSelectSearchEmployee} from "../../../../shared/ui/AsyncSelectSearch/AsyncSelectSearchEmployee";
import {User} from "../../../../entities";
import {useSnackbar} from "notistack";
import {CrmAPI} from "../../../../entities/api/CrmAPI";
import {CreateCRMTaskRequest} from "../../../../entities/DataTransferObjects/requests/CreateCRMTaskRequest";
import {UserCRMTaskWithSteps} from "../../../../entities/DataTransferObjects/responses/UserCRMTaskWithSteps";

const CrmClientTaskCreateModal = (p: { open: boolean, setOpen: (v: boolean) => void, client: User, addTask: (v: UserCRMTaskWithSteps) => void }) => {
    const {enqueueSnackbar} = useSnackbar()

    const options: { name: string, label: string }[] = [
        {name: "ProductSearch", label: "Пошук товару"},
        {name: "WorkInfo", label: "Консультація та прорахунок"},
    ]

    const [cat, setCat] = useState<{ name: string, label: string } | null>(null)
    const [manager, setManager] = useState<User | null>(null)
    const [them, setThem] = useState<string>("")
    const [info, setInfo] = useState<string>("")

    const handleSubmit = () => {
        if (info.length < 3 || them.length < 3 || cat === null)
            enqueueSnackbar('Заповніть усі поля', {variant: 'warning', autoHideDuration: 3000})
        else {
            let data: CreateCRMTaskRequest = {
                clientId: p.client.id,
                managerId: manager?.id,
                thematic: them,
                clientInfo: info,
                taskCategory: cat.name
            }
            CrmAPI.CreateTask(data).then((r) => {
                enqueueSnackbar('Завдання створено', {variant: 'success', autoHideDuration: 3000})
                p.addTask(r.data)
                p.setOpen(false)
            })
        }
    }

    return (
        <CustomModal
            open={p.open}
            onClose={() => {
                p.setOpen(false)
            }}
        >
            <div className={s.modal}>
                <div className={s.row1}>
                    <div className={s.category}>
                        <div style={{flexGrow: "1"}}>
                            <Select
                                value={cat}
                                onChange={setCat}
                                options={options}
                                getOptionLabel={s => s.label}
                                getOptionValue={s => s.label}
                                placeholder={'Категорія завдання'}
                            />
                        </div>
                    </div>
                    <div className={s.manager}>
                        <span>Менеджер:</span>
                        <AsyncSelectSearchEmployee onSelect={() => {
                        }} setValue={(u) => {
                            setManager(u?.user ? u?.user : null)
                        }} shopId={0}/>
                    </div>
                </div>
                <div className={s.row2}>
                    <div className={s.thematic}>
                        <CustomInput placeholder={"Тема завдання"} value={them}
                                     onChange={(v => setThem(v.target.value))}/>
                    </div>
                    <div className={s.save}>
                        <Button onClick={handleSubmit}>Створити</Button>
                    </div>
                </div>
                <div className={s.row3}>
                    <CustomTextarea placeholder={"Детальний описл завдання"} value={info}
                                    onChange={v => setInfo(v.target.value)}/>
                </div>
            </div>
        </CustomModal>
    );
};

export default CrmClientTaskCreateModal;