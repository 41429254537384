import React from 'react';
import s from './OrderModal.module.scss'
import useOrderManager from "../../../../features/OrderManager/OrderManagerStore";
import {LocalStorage, useCurrency} from "../../../../entities";
import {AsyncSelectSearchEmployee} from "../../../../shared/ui/AsyncSelectSearch/AsyncSelectSearchEmployee";
import useOrderModal from "./OrderModalStore";
import CRMClientData from "../../CRM/CRMClientData/CRMClientData";
import CRMClientInteractions from "../../CRM/CRMClientInteractions/CRMClientInteractions";

const OrderModalPage1 = () => {
    const co = useOrderManager(s => s.currentOrder)

    const r = useCurrency(s => s.roundUp)
    const fbts = useCurrency(s => s.fromBaseToSelected)

    const manager = useOrderModal(s => s.manager)
    const setManager = useOrderModal(s => s.setManager)

    if (co == null) return <></>;
    return (
        <div className={s.page1}>
            <div className={s.client}>

                <CRMClientData user={co.client.user}/>

                <div className={s.description}>
                    <div className={s.desc_label}>Інформація від замовника:</div>
                    <div className={s.desc_value}>{co.order.descriptionCilent}</div>
                </div>
            </div>
            <div className={s.manager_select}>
                Менеджер:
                <AsyncSelectSearchEmployee onSelect={() => {
                }} value={manager} setValue={setManager} shopId={parseFloat(LocalStorage.shopId()!)}/>
            </div>
            <div className={s.crm}>
                <CRMClientInteractions client={co.client.user} interactions={[]} addInteraction={() => {
                }}/>
            </div>
        </div>
    );
};

export default OrderModalPage1;