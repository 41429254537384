import {create} from "zustand";
import {devtools, persist} from "zustand/middleware";
import {immer} from "zustand/middleware/immer";
import {
    ProductOptionsWithVariants
} from "../../../features/ProductCatalogFeatures/EditProductCardModal/models/ProductOptionsWithVariants";
import {CreateOption, EntitiesAPI} from "../../../entities";

interface p {
    open: boolean
    setOpen: (v: boolean) => void

    optionsWithVariants: ProductOptionsWithVariants[]
    loadOptionsWithVariants: () => void

    selectedOption: ProductOptionsWithVariants | null
    setSelectedOption: (n: ProductOptionsWithVariants | null) => void

    addVariantOption: (variant: string, os: () => void) => void
    createOption: (option: string, variant: string, os: () => void) => void
}

export const useProductOptionsManagerModal = create<p>()(persist(devtools(immer((set, get) => ({
    open: false,
    setOpen: v => set({open: v}),

    optionsWithVariants: [],
    loadOptionsWithVariants: () => {
        EntitiesAPI.Option.getOptions().then(n => {
            set({optionsWithVariants: n.data})
        })
    },
    selectedOption: null,
    setSelectedOption: n => set({selectedOption: n}),

    addVariantOption: (variant, os) => {
        let selected = get().selectedOption
        if (selected === null) return
        EntitiesAPI.Option.addOptionVariant(selected.id, variant).then((r) => {
            let data = get().optionsWithVariants.map(g => {
                if (g.id === r.data.id) return r.data
                return g
            })
            set({optionsWithVariants: data})

            if (selected!.id === r.data.id) set({selectedOption: r.data})
            os()
        })
    },

    createOption: (option, variant, os) => {
        let data: CreateOption = {
            name: option,
            optionVariants: [variant]
        }

        EntitiesAPI.Option.addNewOption(data).then(r => {

            set(state => {
                state.optionsWithVariants.push(r.data)
            })
            set({selectedOption: r.data})

            os()
        })
    },

}))), {
    name: "useProductOptionsManagerModal",
    version: 1
}));