import React from 'react';
import {CustomModal} from "../../../../shared/ui";
import s from './CRMClientModal.module.scss'
import CRMClientData from "../CRMClientData/CRMClientData";
import {UserCRMData} from "../../../../entities/models/CRM/UserCRMData";
import CRMClientInteractions from "../CRMClientInteractions/CRMClientInteractions";
import CRMClientConversions from "../CRMClientConversions/CRMClientConversions";
import CRMClientTasks from "../CRMClientTasks/CRMClientTasks";
import {UserCRMInteraction} from "../../../../entities/entities/CRM/UserCRMInteraction";

const CrmClientModal = (p: { open: boolean, setOpen: (v: boolean) => void, data: UserCRMData | null, updateData: (v: UserCRMData) => void }) => {

    const handleInterAdd = (v: UserCRMInteraction) => {
        let data = {...p.data!}
        data.interactions = [v, ...data.interactions]
        p.updateData(data)
    }

    if (p.data === null) return null;
    return (
        <CustomModal
            open={p.open}
            onClose={() => {
                p.setOpen(false)
            }}
        >
            <div className={s.wrapper}>
                <div>
                    <CRMClientData user={p.data.user}/>
                </div>
                <div className={s.inter}>
                    <CRMClientInteractions client={p.data.user} interactions={p.data.interactions}
                                           addInteraction={handleInterAdd}/>
                </div>
                <div>
                    <CRMClientConversions conversions={p.data.conversions}/>
                </div>
                <div className={s.tasks}>
                    <CRMClientTasks user={p.data} updateData={p.updateData}/>
                </div>
            </div>
        </CustomModal>
    );
};

export default CrmClientModal;