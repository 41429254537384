import React from 'react';
import s from "./HTMLParser.module.scss";

const HtmlParserPage3 = () => {
    return (
        <div className={s.page3}>
            Парсинг
        </div>
    );
};

export default HtmlParserPage3;