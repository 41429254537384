import React, {useState} from 'react';
import {Button, CustomModal} from "../../../../shared/ui";
import {UserCRMTaskWithSteps} from "../../../../entities/DataTransferObjects/responses/UserCRMTaskWithSteps";
import s from './CRMClientTasks.module.scss'
import {UserCRMInteraction} from "../../../../entities/entities/CRM/UserCRMInteraction";
import CRMClientTaskAddStepModal from "./CRMClientTaskAddStepModal";
import {GetShorterFIO} from "../../../../shared/utils/UserUtilities/GetShorterFIO";
import {DateConvertor} from "../../../../shared/utils/DateUtilities/DateConvertor";
import ExtInfo from "../../../../shared/assets/workspace/CRM/icons8-info (1).svg";
import IncomeCall from "../../../../shared/assets/workspace/CRM/icons8-call-24 (2).png";
import OutcomeCall from "../../../../shared/assets/workspace/CRM/icons8-call-24.png";
import ManualCall from "../../../../shared/assets/workspace/CRM/icons8-call-24 (3).png";
import FaceToFace from "../../../../shared/assets/workspace/CRM/icons8-face-to-face-64.png";
import ManualChat from "../../../../shared/assets/workspace/CRM/icons8-chat-64.png";
import Chat from "../../../../shared/assets/workspace/CRM/icons8-chat-64.png";
import {DarkerColorConverter} from "../../../../shared/utils/ColorUtilities/DarkerColorConverter";

const CrmClientTaskModal = (p: { open: boolean, setOpen: (v: boolean) => void, task: UserCRMTaskWithSteps | null, interactions: UserCRMInteraction[], updateTask: (s: UserCRMTaskWithSteps) => void }) => {
    if (p.task === null) return null
    let taskDate = new Date(p.task!.task.createdAt).getTime()
    let arr: { date: number, row: JSX.Element }[] = []

    const getStatus = (status: string): { label: string, style: {} } => {
        if (status === "WaitingEmployee") return {label: "Очікує ВАС!", style: {background: "#e07e7e"}}
        if (status === "WaitingClient") return {label: "Очікує клієнта", style: {background: "#e0d37e"}}
        if (status === "Closed") return {label: "Закрито", style: {background: "#7ee083"}}
        return {label: "err", style: {}}
    }

    let stepArr = [...p.task!.steps]
    stepArr.push({
        taskId: p.task.task.id,
        id: 0,
        updatedAt: p.task.task.updatedAt,
        createdAt: p.task.task.createdAt,
        enabled: p.task.task.enabled,
        info: "Створення задачі",
        userId: p.task.task.createdId,
        userFIO: p.task.task.createdFIO,
        statusAfter: 'WaitingEmployee',
        managerUpdate: p.task.task.managerId,
        managerUpdateFIO: p.task.task.managerFIO
    })
    stepArr.forEach(n => {
        let el =
            <div className={s.step}>
                <div className={s.column1}>
                    <div className={s.stat} style={getStatus(n.statusAfter).style}>
                        <div className={s.label}>Після кроку:</div>
                        <div className={s.value}>{getStatus(n.statusAfter).label}</div>
                    </div>
                    <div className={s.manager}>
                        <div className={s.label}>Оновлення менеджера:</div>
                        <div
                            className={s.value}>{n.managerUpdateFIO ? GetShorterFIO(n.managerUpdateFIO) : 'Не змінено'}</div>
                    </div>

                </div>
                <div className={s.column2}>
                    <b>{DateConvertor.GetHoursMinutes(n.createdAt) + ' - ' + DateConvertor.GetDate(n.createdAt)}</b>
                    {n.info}
                </div>
            </div>
        arr.push({date: new Date(n.createdAt).getTime(), row: el})
    })

    let buf = p.interactions.filter(n => new Date(n.createdAt).getTime() < taskDate).sort((a, b) => new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime())
    let firstInter = buf.length > 3 ? buf.slice(0, 3) : buf
    let interArr = [...p.interactions.filter(n => new Date(n.createdAt).getTime() > taskDate)]

    const [h, sH] = useState(0)


    const getIcon = (type: string) => {
        if (type === "ExtInfo") return ExtInfo
        if (type === "IncomeCall") return IncomeCall
        if (type === "OutcomeCall") return OutcomeCall
        if (type === "ManualCall") return ManualCall
        if (type === "FaceToFace") return FaceToFace
        if (type === "ManualChat") return ManualChat
        if (type === "Chat") return Chat
        return "NaN"
    }

    const getBg = (n: UserCRMInteraction): {} => {
        if (n.interractionStatus === "Important") return n.id === h ? {background: DarkerColorConverter("#e07e7e", 0.2)} : {background: "#e07e7e"}
        if (n.interractionStatus === "Neutral") return n.id === h ? {background: DarkerColorConverter("#e0d37e", 0.2)} : {background: "#e0d37e"}
        if (n.interractionStatus === "Irrelevant") return n.id === h ? {background: DarkerColorConverter("#7ee083", 0.2)} : {background: "#7ee083"}
        return {}
    }

    interArr ? interArr = [...interArr, ...firstInter!] : null
    interArr.forEach(n => {
        let el =
            <div className={s.inter} onMouseEnter={() => sH(n.id)} onMouseLeave={() => sH(0)} style={getBg(n)}>
                <div className={s.type}>
                    <img className={s.icon} src={getIcon(n.interranctionType)}/>
                    <div className={s.time}>
                        <div className={s.time_info}>{DateConvertor.GetHoursMinutes(n.createdAt)}</div>
                        <div className={s.time_info}>{DateConvertor.GetDateNoYear(n.createdAt)}</div>
                    </div>
                </div>
                <div className={s.data}>
                    <div className={s.thematic}>{n.thematic + ": "}</div>
                    <div className={s.core}>{n.information}</div>
                </div>
            </div>
        arr.push({date: new Date(n.createdAt).getTime(), row: el})
    })

    const [o, so] = useState(false)

    return (
        <CustomModal
            open={p.open}
            onClose={() => {
                p.setOpen(false)
            }}
        >
            <CRMClientTaskAddStepModal open={o} setOpen={so} onTaskUpdate={p.updateTask} task={p.task.task}/>
            <div className={s.mdl}>
                <div className={s.column1}>
                    <div className={s.data} style={getStatus(p.task.task.taskStatus).style}>
                        <div className={s.cat}>{p.task.task.taskCategory + ':'}</div>
                        <div className={s.them}>{p.task.task.thematic}</div>
                    </div>
                    <div className={s.manager}>
                        <div>Менеджер:</div>
                        <div>{p.task.task.managerFIO ? GetShorterFIO(p.task.task.managerFIO) : "Не обран"}</div>
                    </div>
                    <div className={s.info}>{p.task.task.clientInfo}</div>
                </div>
                <div className={s.column2}>
                    <div className={s.row1}>
                        <div>Кроки виконання:</div>
                        <Button onClick={() => so(true)} disabled={p.task.task.taskStatus === "Closed"}>Новий
                            крок</Button>
                        <div className={s.stat}
                             style={getStatus(p.task.task.taskStatus).style}>{getStatus(p.task.task.taskStatus).label}</div>
                    </div>
                    <div className={s.table}>
                        {arr.sort((a, b) => b.date - a.date).map(r => r.row)}
                    </div>
                </div>
            </div>
        </CustomModal>
    )
};

export default CrmClientTaskModal;