import React from 'react';
import s from './ColdClients.module.scss'
import CallButton from "../../../../../shared/ui/CallButton/CallButton";
import {LocalStorage, useCurrency} from "../../../../../entities";
import {useClientManager} from "../../ClientManagerStore";
import {UserCRMData} from "../../../../../entities/models/CRM/UserCRMData";
import {DateConvertor} from "../../../../../shared/utils/DateUtilities/DateConvertor";

interface p {
    client: UserCRMData
}

const ColdClientsRow = (p: p) => {
    const r = useCurrency(r => r.roundUp)
    const setOpen = useClientManager(s => s.setClientModal)
    const setSelected = useClientManager(s => s.setSelected)


    return (
        <div className={s.row} onDoubleClick={() => {
            setOpen(true)
            setSelected(p.client)
        }}>
            <CallButton clientId={p.client.user.id} size={55}/>
            <div className={s.info}>
                <div className={s.label}>Клієнт:</div>
                <div
                    className={s.name}>{p.client.user.lastName + " " + p.client.user.firstName + " " + p.client.user.patronymic}</div>
            </div>
            <div className={s.box}>
                <div className={s.label}>Контакт:</div>
                <div className={s.value}>{DateConvertor.GetHoursMinutes(p.client.user.lastInteraction)}</div>
                <div className={s.value}>{DateConvertor.GetDateNoYear(p.client.user.lastInteraction)}</div>
            </div>
            <div className={s.inter}>
                {p.client.interactions ? p.client.interactions.map((int) =>
                    <div onDoubleClick={() => {
                    }} className={s.inter_item}>
                        <div>{int.interranctionType}</div>
                        <div>{int.thematic}</div>
                    </div>) : null}
            </div>
            <div className={s.inter}>
                {p.client.conversions ? p.client.conversions.map((conv) => <div
                    className={s.inter_item}>
                    <div>{conv.conversionType}</div>
                    <div>{r(conv.total * LocalStorage.currency.fbts()) + LocalStorage.currency.symbol()}</div>
                </div>) : null}
            </div>
        </div>
    );
};

export default ColdClientsRow;