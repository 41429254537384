import React, {useState} from 'react';
import s from './CRMClientInteractions.module.scss'
import {UserCRMInteraction} from "../../../../entities/entities/CRM/UserCRMInteraction";
import {User} from "../../../../entities";
import {Button} from "../../../../shared/ui";
import CRMCreateInteractionModal from "./CRMCreateInteractionModal/CRMCreateInteractionModal";
import {DateConvertor} from "../../../../shared/utils/DateUtilities/DateConvertor";
import ExtInfo from './../../../../shared/assets/workspace/CRM/icons8-info (1).svg'
import ManualCall from './../../../../shared/assets/workspace/CRM/icons8-call-24 (3).png'
import OutcomeCall from './../../../../shared/assets/workspace/CRM/icons8-call-24.png'
import FaceToFace from './../../../../shared/assets/workspace/CRM/icons8-face-to-face-64.png'
import ManualChat from './../../../../shared/assets/workspace/CRM/icons8-chat-64.png'
import Chat from './../../../../shared/assets/workspace/CRM/icons8-chat-64.png'
import IncomeCall from './../../../../shared/assets/workspace/CRM/icons8-call-24 (2).png'
import {DarkerColorConverter} from "../../../../shared/utils/ColorUtilities/DarkerColorConverter";

const CrmClientInteractions = (p: { client: User, interactions: UserCRMInteraction[], addInteraction: (v: UserCRMInteraction) => void }) => {
    const [status, setStatus] = useState<"Irrelevant" | "Neutral" | "Important">("Neutral")
    const [type, setType] = useState<"ManualCall" | "FaceToFace" | "ManualChat" | "ExtInfo">("ManualCall")
    const [h, sH] = useState(0)

    const getIcon = (type: string) => {
        if (type === "ExtInfo") return ExtInfo
        if (type === "IncomeCall") return IncomeCall
        if (type === "OutcomeCall") return OutcomeCall
        if (type === "ManualCall") return ManualCall
        if (type === "FaceToFace") return FaceToFace
        if (type === "ManualChat") return ManualChat
        if (type === "Chat") return Chat
        return "NaN"
    }

    const getBg = (n: UserCRMInteraction): {} => {
        if (n.interractionStatus === "Important") return n.id === h ? {background: DarkerColorConverter("#e07e7e", 0.2)} : {background: "#e07e7e"}
        if (n.interractionStatus === "Neutral") return n.id === h ? {background: DarkerColorConverter("#e0d37e", 0.2)} : {background: "#e0d37e"}
        if (n.interractionStatus === "Irrelevant") return n.id === h ? {background: DarkerColorConverter("#7ee083", 0.2)} : {background: "#7ee083"}
        return {}
    }
    const [d, sd] = useState(false)

    return (
        <div className={s.wrapper}>
            <CRMCreateInteractionModal open={d} setOpen={sd} clientId={p.client.id} type={type} status={status}
                                       onAdd={p.addInteraction}/>
            <div className={s.buttons}>
                <div className={s.action}>
                    <Button className={s.but} onClick={() => {
                        setType("ManualCall")
                        sd(true)
                    }}>Телелефон</Button>
                    <Button className={s.but} onClick={() => {
                        setType("FaceToFace")
                        sd(true)
                    }}>Усно</Button>
                    <Button className={s.but} onClick={() => {
                        setType("ManualChat")
                        sd(true)
                    }}>Переписка</Button>
                    <Button className={s.but} onClick={() => {
                        setType("ExtInfo")
                        sd(true)
                    }}>Інфо</Button>
                </div>
                <div className={s.type}>
                    <div className={s.type_kind} about={"kekw"} onClick={() => {
                        setStatus("Irrelevant")
                    }} style={status === "Irrelevant" ? {
                        background: "#7ee083",
                        border: "1px black solid", height: "30px", width: "30px"
                    } : {background: "#7ee083"}}></div>
                    <div className={s.type_kind} onClick={() => {
                        setStatus("Neutral")
                    }} style={status === "Neutral" ? {
                        background: "#e0d37e",
                        border: "1px black solid", height: "30px", width: "30px"

                    } : {background: "#e0d37e"}}></div>
                    <div className={s.type_kind} onClick={() => {
                        setStatus("Important")
                    }} style={status === "Important" ? {
                        background: "#e07e7e",
                        border: "1px black solid", height: "30px", width: "30px"
                    } : {background: "#e07e7e"}}></div>
                </div>

            </div>
            <div className={s.table}>
                {p.interactions.map(n =>
                    <div className={s.row} onMouseEnter={() => sH(n.id)} onMouseLeave={() => sH(0)} style={getBg(n)}>
                        <div className={s.type}>
                            <img className={s.icon} src={getIcon(n.interranctionType)}/>
                            <div className={s.time}>
                                <div className={s.time_info}>{DateConvertor.GetHoursMinutes(n.createdAt)}</div>
                                <div className={s.time_info}>{DateConvertor.GetDateNoYear(n.createdAt)}</div>
                            </div>
                        </div>
                        <div className={s.data}>
                            <div className={s.thematic}>{n.thematic + ": "}</div>
                            <div className={s.core}>{n.information}</div>
                        </div>
                    </div>
                )}
            </div>
        </div>
    );
};

export default CrmClientInteractions;