import {AxiosResponse} from "axios/index";
import {$api} from "../../shared";

export const PaymentAPI = {
    LiqPay(type: string, id: number): Promise<AxiosResponse<string>> {
        return (
            $api.get<string>(`/payments/liqpay?Target=${type}&TargetId=${id}`)
        )
    },

}