import React from 'react';
import {CustomModal} from "../../../shared/ui";
import s from './UrlPreviewModal.module.scss'

const UrlPreviewModal = (p: { open: boolean, setOpen: (V: boolean) => void, url: string }) => {
    return (
        <CustomModal
            open={p.open}
            onClose={() => {
                p.setOpen(false)
            }}
        >
            <div className={s.wrapper}>
                <iframe title="External Web Page"
                        src={p.url}
                        width="100%" height="100%"/>
            </div>
        </CustomModal>
    );
};

export default UrlPreviewModal;