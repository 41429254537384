import React from 'react';
import LostCallsTable from "./LostCallsTable";
import s from './../../ClientManager.module.scss'

const LostCallsBody = () => {
    return (
        <div className={s.lostcallbody}>
            <LostCallsTable/>
        </div>
    );
};

export default LostCallsBody;