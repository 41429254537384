import React, {useState} from 'react';
import s from './CRMClientTasks.module.scss'
import {UserCRMData} from "../../../../entities/models/CRM/UserCRMData";
import {Button} from "../../../../shared/ui";
import CrmClientTaskCreateModal from "./CRMClientTaskCreateModal";
import {DateConvertor} from "../../../../shared/utils/DateUtilities/DateConvertor";
import {GetShorterFIO} from "../../../../shared/utils/UserUtilities/GetShorterFIO";
import CrmClientTaskModal from "./CRMClientTaskModal";
import {UserCRMTaskWithSteps} from "../../../../entities/DataTransferObjects/responses/UserCRMTaskWithSteps";

const CrmClientTasks = (p: { user: UserCRMData, updateData: (d: UserCRMData) => void }) => {

    const getStatus = (status: string): { label: string, style: {} } => {
        if (status === "WaitingEmployee") return {label: "Очікує ВАС!", style: {background: "#e07e7e"}}
        if (status === "WaitingClient") return {label: "Очікує клієнта", style: {background: "#e0d37e"}}
        if (status === "Closed") return {label: "Закрито", style: {background: "#7ee083"}}
        return {label: "err", style: {}}
    }

    const [o, so] = useState(false)
    const [o2, so2] = useState(false)
    const [sel, setSel] = useState<UserCRMTaskWithSteps | null>(null)


    const handleTaskAdd = (v: UserCRMTaskWithSteps) => {
        let data = {...p.user!}
        data.tasks = [v, ...data.tasks]
        p.updateData(data)
    }

    const handleTaskUpdate = (v: UserCRMTaskWithSteps) => {
        let data = {...p.user!}
        data.tasks = data.tasks.map(t => {
            if (t.task.id === v.task.id) {
                return v
            }
            return t
        })
        setSel(v)
        p.updateData(data)
    }

    let arr = [...p.user.tasks]
    return (
        <div className={s.wrapper}>
            <CrmClientTaskCreateModal open={o} setOpen={so} client={p.user.user} addTask={handleTaskAdd}/>
            <CrmClientTaskModal open={o2} setOpen={so2} task={sel} interactions={p.user.interactions}
                                updateTask={handleTaskUpdate}/>
            <div className={s.buttons}>
                <Button onClick={() => {
                    so(true)
                }}>Створити завдання</Button>
            </div>
            <div className={s.table}>
                {arr.sort((a, b) => new Date(b.task.createdAt).getTime() - new Date(a.task.createdAt).getTime()).map(n => {
                        let stat = getStatus(n.task.taskStatus)
                        return (<div className={s.row} onDoubleClick={() => {
                            setSel(n)
                            so2(true)
                        }}>
                            <div className={s.data}>
                                <div className={s.stat} style={stat.style}>{stat.label}</div>
                                <div
                                    className={s.date}>{DateConvertor.GetHoursMinutes(n.task.createdAt) + " - " + DateConvertor.GetDate(n.task.createdAt)}</div>
                            </div>
                            <div className={s.users}>
                                <div className={s.manager}>
                                    <div className={s.label}>Менеджер:</div>
                                    <div
                                        className={s.value}>{n.task.managerFIO ? GetShorterFIO(n.task.managerFIO) : "Не обран"}</div>
                                </div>
                                <div className={s.user}>
                                    <div className={s.label}>Створив:</div>
                                    <div
                                        className={s.value}>{n.task.createdFIO ? GetShorterFIO(n.task.createdFIO) : ""}</div>
                                </div>
                            </div>
                            <div className={s.thematic}>
                                <div className={s.label}>{n.task.taskCategory}:</div>
                                <div className={s.value}>{n.task.thematic}</div>
                            </div>
                        </div>)
                    }
                )}
            </div>
        </div>
    );
};

export default CrmClientTasks;