import {create} from "zustand"
import {devtools, persist} from "zustand/middleware"
import {immer} from "zustand/middleware/immer"
import * as signalR from '@microsoft/signalr';
import {LocalStorage} from "./LocalStorage";
import {CustomError} from "../errors/workspaceErrors";

interface p {
    isLoading: boolean
    setIsLoading: (value: boolean) => void

    navTo: string | null
    setNavTo: (v: string | null) => void

    isWarningProcessed: boolean
    setIsWarningProcessed: (v: boolean) => void
    warning: null | string

    AgentHubConnection: signalR.HubConnection | null
    AgentHubStartConnection: (s: () => void, f: () => void) => void
    setAgentTerminalConfirm: (v: (data: { id: string, amount: number, agentId: number }) => void) => void
    setAgentTerminalCancel: (v: (data: { id: string, amount: number, agentId: number }) => void) => void
    createAgentHubConnection: () => void
    AgentPrintBill: (DataId: number, Copies: number) => void
    AgentPrintProductSticker: (DataId: number, Copies: number) => void
    AgentPrintEncashment: (DataId: number, Copies: number) => void
    AgentPrintServiceSticker: (DataId: number, Copies: number) => void
    AgentPrintServiceIncomeAct: (DataId: number, Copies: number) => void
    AgentPrintServiceOutcomeSmallAct: (DataId: number, Copies: number) => void
    AgentPrintServiceOutcomeFullAct: (DataId: number, Copies: number) => void

    CheckAgentHubConnection: (os: () => void) => void

    lastError: CustomError | null
    setLastError: (v: CustomError | null) => void

}

export const useApp = create<p>()(persist(devtools(immer((set, get) => ({
    lastError: null,
    setLastError: (v) => set({lastError: v}),

    navTo: null,
    setNavTo: v => set({navTo: v}),
    AgentHubStartConnection: (s, f) => {
        get().AgentHubConnection?.start().then(() => s()).catch(() => f());
    },

    CheckAgentHubConnection: (os) => {
        let con = get().AgentHubConnection
        if (con === null || con.state === undefined) {
            console.log("Creating new AgentHub connection")
            get().createAgentHubConnection()
            get().AgentHubStartConnection(os, () => {
            })
        } else {
            if (con.state === signalR.HubConnectionState.Disconnected) {
                console.log("Starting connection")
                get().AgentHubStartConnection(os, () => {
                })
            } else os()
        }
    },
    //AgentPrint
    AgentPrintBill: (id, copies) => {
        get().CheckAgentHubConnection(() => {
            get().AgentHubConnection?.send("PrintBill", {
                AgentId: parseFloat(LocalStorage.agentId()!),
                DataId: id,
                Copies: copies,
                CurrencyId: LocalStorage.currency.id()
            });
        })
    },
    AgentPrintProductSticker: (id, copies) => {
        get().CheckAgentHubConnection(() => {
            get().AgentHubConnection?.send("PrintProductSticker", {
                AgentId: parseFloat(LocalStorage.agentId()!),
                DataId: id,
                Copies: copies,
                CurrencyId: LocalStorage.currency.id()
            });
        })

    },
    AgentPrintServiceIncomeAct: (id, copies) => {
        get().CheckAgentHubConnection(() => {
            get().AgentHubConnection?.send("PrintServiceIncomeAct", {
                AgentId: parseFloat(LocalStorage.agentId()!),
                DataId: id,
                Copies: copies,
                CurrencyId: LocalStorage.currency.id()
            });
        })

    },
    AgentPrintServiceOutcomeSmallAct: (id, copies) => {
        get().CheckAgentHubConnection(() => {
            get().AgentHubConnection?.send("PrintServiceOutcomeShort", {
                AgentId: parseFloat(LocalStorage.agentId()!),
                DataId: id,
                Copies: copies,
                CurrencyId: LocalStorage.currency.id()
            });
        })

    },
    AgentPrintServiceOutcomeFullAct: (id, copies) => {
        get().CheckAgentHubConnection(() => {
            get().AgentHubConnection?.send("PrintServiceOutcomeFull", {
                AgentId: parseFloat(LocalStorage.agentId()!),
                DataId: id,
                Copies: copies,
                CurrencyId: LocalStorage.currency.id()
            });
        })

    },
    AgentPrintServiceSticker: (id, copies) => {
        get().CheckAgentHubConnection(() => {
            get().AgentHubConnection?.send("PrintServiceSticker", {
                AgentId: parseFloat(LocalStorage.agentId()!),
                DataId: id,
                Copies: copies,
                CurrencyId: LocalStorage.currency.id()
            });
        })

    },
    AgentPrintEncashment: (id, copies) => {
        get().CheckAgentHubConnection(() => {
            get().AgentHubConnection?.send("PrintEncashment", {
                AgentId: parseFloat(LocalStorage.agentId()!),
                DataId: id,
                Copies: copies,
                CurrencyId: LocalStorage.currency.id()
            });
        })

    },
    //

    setAgentTerminalCancel: (v) => {
        get().AgentHubConnection!.on('CancelPay', (data: { id: string, amount: number, agentId: number }) => {
            console.warn('cancelPayment')
            v(data)
        })
    },
    setAgentTerminalConfirm: (v) => {
        get().AgentHubConnection!.on('ConfirmPay', (data: { id: string, amount: number, agentId: number }) => {
            console.warn('confirmPayment')
            v(data)
        })
    },
    createAgentHubConnection: () => {
        const environment = process.env.NODE_ENV;

        const API_URL_DEVELOPMENT = "https://bikeshop.1gb.ua/api/";
        const API_URL_PRODUCTION = "https://api.bikelove.com.ua/";
        let connection = new signalR.HubConnectionBuilder()
            .withUrl((environment === "production" ? API_URL_PRODUCTION : API_URL_DEVELOPMENT) + "agenthub")
            .build();

        set({AgentHubConnection: connection})
    },
    AgentHubConnection: null,
    warning: null,
    isWarningProcessed: true,
    setIsWarningProcessed: (v) => set({isWarningProcessed: v}),
    isLoading: false,
    setIsLoading: (value) => set({
        isLoading: value
    }),
}))), {
    name: "appStore",
    version: 1
}));