import React from 'react'
import s from './ProductCatalogTable.module.scss'
import ProductCatalogTableRow from "./ProductCatalogTableRow";
import useCardCatalogStore from "../../../../pages/workspace/CardCatalog/CardCatalogStore";
import {EditProductCardModal} from "../../../../features";
import {Loader} from "../../../../shared/ui/Loader/Loader";
import {ProductCatalogTableContextMenu} from "./ProductCatalogTableContextMenu";


export const ProductCatalogTable = () => {
    const st = useCardCatalogStore(s => s)

    if (st.isLoading) return (
        <div className={s.wrapper} style={{display: "flex", justifyContent: "center"}}><Loader
            variant={"ellipsis"}/>
        </div>)
    return (
        <div className={s.wrapper}>
            <ProductCatalogTableContextMenu/>
            <EditProductCardModal onUpd={st.updateItem}/>
            {st.catalogState?.products.map((product, index) => <ProductCatalogTableRow key={index} product={product}
                                                                                       onClick={() => {
                                                                                           st.setSelectedRow(product)
                                                                                       }}
                                                                                       isSelected={st.selectedRow?.product.id === product.product.id}/>)}
        </div>
    )

}