import React, {useState} from 'react';
import s from './HTMLParser.module.scss'
import HTMLParserPage1 from "./HTMLParserPage1";
import HTMLParserPage2 from "./HTMLParserPage2";
import HTMLParserPage3 from "./HTMLParserPage3";

const HtmlParser = () => {
    const [page, setPage] = useState(1)
    return (
        <div className={s.wrapper}>
            <div className={s.header}>
                <div className={s.page_button} onClick={() => {
                    setPage(1)
                }}>Сбор ссылок
                </div>
                <div className={s.page_button} onClick={() => {
                    setPage(2)
                }}>Настройка границ
                </div>
                <div className={s.page_button} onClick={() => {
                    setPage(3)
                }}>Парсинг
                </div>
            </div>
            {page === 1 ? <HTMLParserPage1/> : <></>}
            {page === 2 ? <HTMLParserPage2/> : <></>}
            {page === 3 ? <HTMLParserPage3/> : <></>}
        </div>
    );
};

export default HtmlParser;