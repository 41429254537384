import React from 'react';
import s from './LostCalls.module.scss'
import CallButton from "../../../../../shared/ui/CallButton/CallButton";
import {Call} from "../../../../../entities/models/CRM/Call";
import {UserCRMInteraction} from "../../../../../entities/entities/CRM/UserCRMInteraction";

interface p {
    call: Call
    interactions: UserCRMInteraction[]
}

const LostCallsRow = (p: p) => {

    function calculateTimeDifference(targetTime: string): string {
        // Parse the target time string
        const targetDate = new Date(targetTime);

        // Get the current time
        const currentTime = new Date();

        // Calculate the time difference in milliseconds
        const timeDifference = currentTime.getTime() - targetDate.getTime();

        // Convert the time difference to hours and minutes
        const minutes = Math.floor((timeDifference / (1000 * 60)) - 120);

        // Format the result
        let formattedResult: string = ``;
        formattedResult = formattedResult + `${minutes}хв`

        return formattedResult;
    }

    return (
        <div className={s.row}>
            <CallButton size={54} clientId={p.call.secondId}/>
            <div className={s.info}>
                <div className={s.label}>Хто дзвонив:</div>
                <div className={s.name}>
                    {p.call.secondName ? p.call.secondName : "Новий клієнт"}
                </div>
            </div>
            <div className={s.box}>
                <div className={s.label}>Дата:</div>
                <div className={s.value}>{new Date(p.call.callStarted).toLocaleDateString('uk-UA', {
                    day: '2-digit',
                    month: '2-digit',
                    timeZone: 'Europe/Kiev'
                })}</div>
                <div
                    className={s.value}>{new Date(p.call.callStarted).toLocaleTimeString().replace(/:\d{2}\s?(AM|PM)?$/, '')}</div>
            </div>
            <div className={s.box}>
                <div className={s.label}>Втрачено:</div>
                <div className={s.value} style={{color: "#dc0000"}}>{calculateTimeDifference(p.call.callStarted)}</div>
            </div>
            <div className={s.inter}>Interactions</div>
            <div className={s.box}>{p.call.recordingStatus === 'uploaded' ? "Available" : "NO"}</div>
        </div>
    );
};

export default LostCallsRow;