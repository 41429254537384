import {create} from "zustand";
import {devtools, persist} from "zustand/middleware";
import {immer} from "zustand/middleware/immer";
import {DriveFile} from "../../../entities/entities/Drive/DriveFile";
import {DriveAPI} from "../../../entities/api/DriveAPI";

interface p {
    loadFolders: () => void
    foldersTree: DriveFile[]

    selectedFolder: DriveFile | null
    selectedFile: DriveFile | null

    setSelectedFolder: (v: DriveFile | null) => void
    setSelectedFile: (v: DriveFile | null) => void

    filesTable: DriveFile[]
    getFilesTable: (ParentId: string) => void

    uploadFile: (file: any) => void
    grantAccess: (email: string, id: string, os: () => void) => void

    fileName: string
    setFileName: (v: string) => void

    createDoc: (os: () => void) => void
    createSheet: (os: () => void) => void
    createFolder: (os: () => void) => void
    deleteFile: (fileId: string, os: () => void) => void
}

export const useDrivePage = create<p>()(persist(devtools(immer((set, get) => ({
    deleteFile: (fileId: string, os: () => void) => {
        if (get().selectedFolder === null) return
        DriveAPI.DeleteFile(fileId).then((r) => {
            get().setFileName('')
            os()
            set({filesTable: get().filesTable.filter(n => n.documentId != fileId)})
            get().loadFolders()
        })
    },
    createFolder: (os: () => void) => {
        if (get().selectedFolder === null) return
        DriveAPI.CreateFolder(get().fileName, get().selectedFolder!.documentId).then((r) => {
            get().setFileName('')
            os()
            set({filesTable: [...get().filesTable, r.data]})

            get().loadFolders()
        })
    },
    createDoc: (os: () => void) => {
        if (get().selectedFolder === null) return
        DriveAPI.CreateDoc(get().fileName, get().selectedFolder!.documentId).then((r) => {
            get().setFileName('')
            os()
            set({filesTable: [...get().filesTable, r.data]})

        })
    },
    createSheet: (os: () => void) => {
        if (get().selectedFolder === null) return
        DriveAPI.CreateSheet(get().fileName, get().selectedFolder!.documentId).then((r) => {
            get().setFileName('')
            os()
            set({filesTable: [...get().filesTable, r.data]})

        })
    },
    fileName: '',
    setFileName: (v) => set({fileName: v}),
    grantAccess: (email: string, id: string, os: () => void) => {
        DriveAPI.GrantWrite(email, id).then(os)
    },
    selectedFile: null,
    selectedFolder: null,
    setSelectedFile: v => set({selectedFile: v}),
    setSelectedFolder: v => set({selectedFolder: v}),
    getFilesTable: (ParentId) => {
        DriveAPI.GetFilesByFolder(ParentId).then((r) => {
            set({filesTable: r.data})
        })
    },
    loadFolders: () => {
        DriveAPI.GetFoldersTree().then((r) => {
            set({foldersTree: r.data})
        })
    },
    foldersTree: [],
    filesTable: [],
    uploadFile: (file) => {
        if (get().selectedFolder === null) return
        DriveAPI.UploadFile(file, get().selectedFolder!.documentId).then(r => {
            console.log('fileLoaded', r.data)
            set({filesTable: [...get().filesTable, r.data]})
        })
    }

}))), {
    name: "useNomenclatureManager",
    version: 1
}));