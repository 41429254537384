import React, {useState} from 'react';
import s from './ClientManager.module.scss'
import {AsyncSelectSearchUser} from "../../../shared/ui/AsyncSelectSearch/AsyncSelectSearchUser";
import {Button} from "../../../shared/ui";
import LostCallsBody from "./Bodies/LostCalls/LostCallsBody";
import DealsBody from "./Bodies/Deals/DealsBody";
import HotClientsBody from "./Bodies/HotClients/HotClientsBody";
import ColdClientsBody from "./Bodies/ColdClients/ColdClientsBody";
import CRMClientModal from "../../../widgets/workspace/CRM/CRMClientModal/CRMClientModal";
import {useClientManager} from "./ClientManagerStore";
import {CrmAPI} from "../../../entities/api/CrmAPI";

const ClientManager = () => {
    const [sel, setSel] = useState(1)
    const open = useClientManager(s => s.clientModal)
    const setOpen = useClientManager(s => s.setClientModal)
    const selected = useClientManager(s => s.selected)
    const updateUserData = useClientManager(s => s.updateUserData)
    const setSelected = useClientManager(s => s.setSelected)

    return (
        <div className={s.wrapper}>
            <CRMClientModal open={open} setOpen={setOpen} data={selected} updateData={updateUserData}/>
            <div className={s.head}>
                <Button className={s.head_lostBT} style={sel === 1 ? {background: "red"} : {}} onClick={() => {
                    setSel(1)
                }}>Втрачені дзвінки</Button>
                <Button className={s.head_dealsBT} style={sel === 2 ? {background: "red"} : {}} onClick={() => {
                    setSel(2)
                }}>Угоди</Button>
                <Button className={s.head_hotBT} style={sel === 3 ? {background: "red"} : {}} onClick={() => {
                    setSel(3)
                }}>Гарячі клієнти</Button>
                <Button className={s.head_coldBT} style={sel === 4 ? {background: "red"} : {}} onClick={() => {
                    setSel(4)
                }}>Холодні клієнти</Button>
                <div className={s.head_search}>
                    <AsyncSelectSearchUser onSelect={(u) => {
                        if (u === null) return
                        CrmAPI.DataById(u.user.id).then((r) => {
                            setSelected(r.data)
                            setOpen(true)
                        })
                    }}/>
                </div>
            </div>
            <div className={s.body}>
                {sel === 1 ? <LostCallsBody/> : null}
                {sel === 2 ? <DealsBody/> : null}
                {sel === 3 ? <HotClientsBody/> : null}
                {sel === 4 ? <ColdClientsBody/> : null}
            </div>
        </div>
    );
};

export default ClientManager;